import React, { useState, useEffect } from "react";
import { postRequest } from "../axios";
import parse from 'html-react-parser';

import PageHeader from "./common/PageHeader";
import { getSessionData, getUserType, getUserData } from "../utils/Helpers";
import { useOutletContext } from "react-router-dom";

const Almanac = () => {
  const [almanacList, setAlmanacList] = useState([]);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getAlmanacList();
  }, []);

  let stdClass = "";
  if (getUserType() !== 'staff') {
    stdClass = getUserData().stdClass;
  }

  const getAlmanacList = async () => {
    setSpinner(true);
    const response = await postRequest("get-almanac-by-pages", {
      session_code: getSessionData().code,
      user_type: getUserType(),
      std_class: stdClass,
    });
    setAlmanacList(response.data.response.pages);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Almanac"
        pageIcon={<i className="subheader-icon fal fa-book"></i>}
      />
      <div className="row">
        <div className="col-lg-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Almanac</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row">
                  <div className="col-auto">
                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      {almanacList &&
                        almanacList.length > 0 &&
                        almanacList.map((almanac, k) => {
                          return (
                            <a className={"nav-link " + (k === 0 ? "active" : "")} id={"v-pills-" + almanac.id + "-tab"}
                              data-toggle="pill" href={"#v-pills-" + almanac.id} role="tab"
                              aria-controls={"v-pills-" + almanac.id} aria-selected={k === 0 ? "active" : ""}>
                              <span className="badge badge-primary badge-pill">{almanac.pageNumber}</span>
                              <span className="hidden-sm-down ml-1">{almanac.headingText}</span>
                            </a>
                          );
                        })}
                    </div>
                  </div>
                  <div className="col table-responsive">
                    <div className="tab-content" id="v-pills-tabContent">
                      {almanacList &&
                        almanacList.length > 0 &&
                        almanacList.map((almanac, p) => {
                          return (
                            <div key={p} className={"tab-pane fade" + (p === 0 ? "show active" : "")} id={"v-pills-" + almanac.id} role="tabpanel" aria-labelledby={"v-pills-" + almanac.id + "-tab"}>
                              <h3>
                                <span className="badge badge-danger badge-pill">{almanac.headingText}</span>
                              </h3>
                              {parse(almanac.bodyContent)}
                              <div className="footer">
                                Page - <span className="badge badge-primary badge-pill">{almanac.pageNumber}</span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Almanac;
