import React from "react";
import { Row, Col } from "antd";

import PageHeader from "../common/PageHeader";

const NoPermission = () => {
  return (
    <div id="content">
      <PageHeader
        pageTitle="No Permission"
        pageIcon={<i className="subheader-icon fal fa-ban"></i>}
      />
      <Row gutter={[15]}>
        <div className="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center">
          <h1 className="page-error color-fusion-500">
            <span className="text-gradient">SORRY!!</span>
            <small className="fw-500">
              You have <u>NO</u> Permission
            </small>
            <h3>Kindly contact school authority for more details</h3>
          </h1>
        </div>
      </Row>
    </div>
  );
};

export default NoPermission;
