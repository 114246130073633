import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../Config";
import moment from "moment";
import {
  Input,
  Row,
  Col,
  Select,
  Form,
  Button,
  DatePicker,
  Space
} from "antd";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { useParams } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

const AppStudentProfileEdit = (props) => {
  const navigate = useNavigate();
  const { token, dbtoken, sid } = useParams();
  const dateFormat = "DD-MM-YYYY";
  const [form] = Form.useForm();

  const [state, setState] = useState({
    std_name: null,
    std_birth_date: moment().format("DD-MM-YYYY"),
    std_gender: [],
    std_caste: [],
    std_religion: [],
    std_blood_group: [],
    std_nationality: [],
    std_mother_tongue: [],
    std_disability: [],
    std_id_mark: null,
    std_aadhaar: null,
    std_bpl: [],

    std_village: null,
    std_habitation_locality: null,
    std_po: null,
    std_ps: null,
    std_district: [],
    std_block: [],
    std_pin: null,
    std_contact: null,
    std_contact_whatsapp: null,
    std_email: null,

    std_father_name: null,
    std_father_education: [],
    std_father_occu: null,
    std_father_office_name: null,
    std_father_contact: null,
    std_father_income: [],
    std_mother_name: null,
    std_mother_education: [],
    std_mother_occu: null,
    std_mother_office_name: null,
    std_mother_contact: null,
    std_mother_income: [],
    std_guardian_name: null,
    std_guardian_education: [],
    std_guardian_occu: null,
    std_guardian_relation: null,
    std_guardian_contact: null,
    std_guardian_income: [],

    std_comment: null,
    profile_update_status: null
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [editingFields, setEditingFields] = useState([]);

  const [stdGender, setStdGender] = useState([]);
  const [stdCaste, setStdCaste] = useState([]);
  const [stdReligion, setStdReligion] = useState([]);
  const [stdBloodGroup, setStdBloodGroup] = useState([]);
  const [stdNationality, setStdNationality] = useState([]);
  const [stdMotherTongue, setStdMotherTongue] = useState([]);
  const [stdBpl, setStdBpl] = useState([]);
  const [stdDisability, setStdDisability] = useState([]);
  const [stdDistrict, setStdDistrict] = useState([]);
  const [stdBlockMunicipality, setStdBlockMunicipality] = useState([]);
  const [stdEducation, setStdEducation] = useState([]);
  const [stdRelation, setStdRelation] = useState([]);
  const [annualIncome, setAnnualIncome] = useState([]);

  const headers = {
    DBAuth: dbtoken,
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const getUtilityArrays = async () => {
      const response = await axios.post(Config.API_URL + "get-utility-arrays", state, { headers });

      setStdGender(response.data.response.gender);
      setStdCaste(response.data.response.caste);
      setStdReligion(response.data.response.religion);
      setStdBloodGroup(response.data.response.bloodGroup);
      setStdNationality(response.data.response.nationality);
      setStdMotherTongue(response.data.response.motherTongue);
      setStdBpl(response.data.response.bpl);
      setStdDisability(response.data.response.disability);
      setStdDistrict(response.data.response.district);
      setStdEducation(response.data.response.education);
      setStdRelation(response.data.response.relation);
      setAnnualIncome(response.data.response.annualIncome);
    }
    getUtilityArrays();
  }, []);

  useEffect(() => {
    const getStudentProfile = async () => {
      const response = await axios.post(Config.API_URL + "get-student-personal-details-edit", sid, { headers });
      const resData = response.data.response;

      setState({
        std_name: resData.std_name,
        std_birth_date: resData.std_birth_date !== "" ? resData.std_birth_date : "",
        std_gender: resData.std_gender,
        std_caste: resData.std_caste,
        std_religion: resData.std_religion,
        std_blood_group: resData.std_blood_group,
        std_nationality: resData.std_nationality,
        std_mother_tongue: resData.std_mother_tongue,
        std_disability: resData.std_disability,
        std_id_mark: resData.std_id_mark,
        std_aadhaar: resData.std_aadhaar,
        std_bpl: resData.std_bpl,

        std_village: resData.std_village,
        std_habitation_locality: resData.std_habitation_locality,
        std_po: resData.std_po,
        std_ps: resData.std_ps,
        std_district: resData.std_district,
        // std_block: resData.std_block,
        std_pin: resData.std_pin,
        std_contact: resData.std_contact,
        std_contact_whatsapp: resData.std_contact_whatsapp,
        std_email: resData.std_email,

        std_father_name: resData.std_father_name,
        std_father_education: resData.std_father_education,
        std_father_occu: resData.std_father_occu,
        std_father_office_name: resData.std_father_office_name,
        std_father_contact: resData.std_father_contact,
        std_father_income: resData.std_father_income,
        std_mother_name: resData.std_mother_name,
        std_mother_education: resData.std_mother_education,
        std_mother_occu: resData.std_mother_occu,
        std_mother_office_name: resData.std_mother_office_name,
        std_mother_contact: resData.std_mother_contact,
        std_mother_income: resData.std_mother_income,
        std_guardian_name: resData.std_guardian_name,
        std_guardian_education: resData.std_guardian_education,
        std_guardian_occu: resData.std_guardian_occu,
        std_guardian_relation: resData.std_guardian_relation,
        std_guardian_contact: resData.std_guardian_contact,
        std_guardian_income: resData.std_guardian_income,
        profile_update_status: resData.profile_update_status,
      });

      if (resData.profile_update_status === 1) {
        form.setFieldsValue({
          std_name: resData.std_name,
          std_birth_date: resData.std_birth_date !== "" ? moment(resData.std_birth_date, "DD-MM-YYYY") : "",
          std_gender: resData.std_gender,
          std_caste: resData.std_caste,
          std_religion: resData.std_religion,
          std_blood_group: resData.std_blood_group,
          std_nationality: resData.std_nationality,
          std_mother_tongue: resData.std_mother_tongue,
          std_disability: resData.std_disability,
          std_id_mark: resData.std_id_mark,
          std_aadhaar: resData.std_aadhaar,
          std_bpl: resData.std_bpl,

          std_village: resData.std_village,
          std_habitation_locality: resData.std_habitation_locality,
          std_po: resData.std_po,
          std_ps: resData.std_ps,
          std_district: resData.std_district,
          // std_block: resData.std_block,
          std_pin: resData.std_pin,
          std_contact: resData.std_contact,
          std_contact_whatsapp: resData.std_contact_whatsapp,
          std_email: resData.std_email,

          std_father_name: resData.std_father_name,
          std_father_education: resData.std_father_education,
          std_father_occu: resData.std_father_occu,
          std_father_office_name: resData.std_father_office_name,
          std_father_contact: resData.std_father_contact,
          std_father_income: resData.std_father_income,
          std_mother_name: resData.std_mother_name,
          std_mother_education: resData.std_mother_education,
          std_mother_occu: resData.std_mother_occu,
          std_mother_office_name: resData.std_mother_office_name,
          std_mother_contact: resData.std_mother_contact,
          std_mother_income: resData.std_mother_income,
          std_guardian_name: resData.std_guardian_name,
          std_guardian_education: resData.std_guardian_education,
          std_guardian_occu: resData.std_guardian_occu,
          std_guardian_relation: resData.std_guardian_relation,
          std_guardian_contact: resData.std_guardian_contact,
          std_guardian_income: resData.std_guardian_income,
        });
      }

      getStdBlockMunicipality(resData.std_district);
    }

    const getEditingFields = async () => {
      const response = await axios.post(Config.API_URL + "get-fields-for-editing", state, { headers });
      if (response.data.error === 0) {
        setEditingFields(response.data.response);
        getStudentProfile();
      } else {
        ErrorNotificationMsg(response.data.errmsg);
        navigate("/failed");
      }
    }
    getEditingFields();
  }, [sid, navigate, stdBpl, stdDisability]);

  const getStdBlockMunicipality = async (distCode) => {
    const blockList = await axios.post(Config.API_URL + "get-block-municipality-by-district", {
      district: distCode,
    }, { headers });

    setStdBlockMunicipality(blockList.data.response);
  }

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value });
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const handleDateChange = (date, dateString) => {
    setState({ ...state, std_birth_date: moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD") });
    form.setFieldsValue({ field: dateString });
  };

  const handleDistrictChange = async (field, value) => {
    setState({ ...state, [field]: value, std_block: [] });
    form.setFieldsValue({ std_block: [] });

    getStdBlockMunicipality(value);
  };

  const checkUpdateStatus = (status) => {
    if (status === 0) {
      return (
        <div className="row p-2">
          <div className="col-md-12" >
            <div className="alert alert-info fade show px-3 py-2 mb-1 mt-1" >
              Not enabled for updation
            </div>
          </div>
        </div>
      );
    }
    if (status === 2) {
      return (
        <div className="row p-2">
          <div className="col-md-12" >
            <div className="alert alert-info fade show px-3 py-2 mb-1 mt-1" >
              Already applied. Kindly wait for verification from school authority
            </div>
          </div>
        </div>
      );
    }
    if (status === 3) {
      return (
        <div className="row p-2">
          <div className="col-md-12" >
            <div className="alert alert-info fade show px-3 py-2 mb-1 mt-1" >
              Submitted data verified
            </div>
          </div>
        </div>
      );
    }
  }

  const onFinish = async () => {
    setBtnLoading(true);
    const payload = {
      std_name: state.std_name,
      std_birth_date: state.std_birth_date,
      std_gender: state.std_gender,
      std_caste: state.std_caste,
      std_religion: state.std_religion,
      std_blood_group: state.std_blood_group,
      std_nationality: state.std_nationality,
      std_mother_tongue: state.std_mother_tongue,
      std_disability: state.std_disability,
      std_id_mark: state.std_id_mark,
      std_aadhaar: state.std_aadhaar,
      std_bpl: state.std_bpl,

      std_village: state.std_village,
      std_habitation_locality: state.std_habitation_locality,
      std_po: state.std_po,
      std_ps: state.std_ps,
      std_district: state.std_district,
      std_block: state.std_block,
      std_pin: state.std_pin,
      std_contact: state.std_contact,
      std_contact_whatsapp: state.std_contact_whatsapp,
      std_email: state.std_email,

      std_father_name: state.std_father_name,
      std_father_education: state.std_father_education,
      std_father_occu: state.std_father_occu,
      std_father_office_name: state.std_father_office_name,
      std_father_contact: state.std_father_contact,
      std_father_income: state.std_father_income,
      std_mother_name: state.std_mother_name,
      std_mother_education: state.std_mother_education,
      std_mother_occu: state.std_mother_occu,
      std_mother_office_name: state.std_mother_office_name,
      std_mother_contact: state.std_mother_contact,
      std_mother_income: state.std_mother_income,
      std_guardian_name: state.std_guardian_name,
      std_guardian_education: state.std_guardian_education,
      std_guardian_occu: state.std_guardian_occu,
      std_guardian_relation: state.std_guardian_relation,
      std_guardian_contact: state.std_guardian_contact,
      std_guardian_income: state.std_guardian_income,

      std_comment: state.std_comment,
    };

    try {
      const res = await axios.post(Config.API_URL + "update-student-details", payload, { headers });

      if (res.data.response === "success") {
        SuccessNotificationMsg("Success", "Profile updated successfully.");
        setBtnLoading(false);
        navigate("/success");
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg("Error in Profile Update.");
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg("Something went wrong!!");
    }
  }

  const onFinishFailed = () => {
    ErrorNotificationMsg('Kindly enter all mandatory fields!!');
  };

  const regExpCharacter = /^[a-zA-Z\.\s]*$/;
  const regExpNumber = /^\d+$/;

  return (
    <div id="panel-1" className="panel">
      <div className="panel-container show">
        <div className="panel-content p-0">
          {state.profile_update_status === 1 ?
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              // ref={formRef}
              autoComplete="off"
              layout="vertical"
            >
              <div className="panel-content">
                <div className="panel-tag px-3 py-2"><strong>Personal Details</strong></div>
                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item
                      label="Student's Name"
                      name="std_name"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharacter),
                          message: "Please enter student name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_name", value)}
                        disabled={!editingFields.includes('std_name')}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      name="std_birth_date"
                      label="Date of Birth"
                      rules={[
                        {
                          required: editingFields.includes('std_birth_date') ? true : false,
                          message: "Please enter date of birth!",
                        },
                      ]}
                    >
                      <DatePicker
                        allowClear={false}
                        defaultValue={moment()}
                        format={dateFormat}
                        onChange={handleDateChange}
                        style={{ width: "100%" }}
                        disabled={!editingFields.includes('std_birth_date')}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Gender"
                      name="std_gender"
                      rules={[
                        {
                          required: editingFields.includes('std_gender') ? true : false,
                          message: "Please select gender!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Gender"
                        onChange={(value) =>
                          handleSelectChange("std_gender", value)
                        }
                        disabled={!editingFields.includes('std_gender')}
                      >
                        {!!stdGender &&
                          stdGender.map((gen) => (
                            <Option key={gen.id} value={gen.id}>
                              {gen.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Caste"
                      name="std_caste"
                      rules={[
                        {
                          required: editingFields.includes('std_caste') ? true : false,
                          message: "Please select caste!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Caste"
                        onChange={(value) =>
                          handleSelectChange("std_caste", value)
                        }
                        disabled={!editingFields.includes('std_caste')}
                      >
                        {!!stdCaste &&
                          stdCaste.map((caste) => (
                            <Option key={caste.id} value={caste.id}>
                              {caste.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Religion"
                      name="std_religion"
                      rules={[
                        {
                          required: editingFields.includes('std_religion') ? true : false,
                          message: "Please select religion!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Religion"
                        onChange={(value) =>
                          handleSelectChange("std_religion", value)
                        }
                        disabled={!editingFields.includes('std_religion')}
                      >
                        {!!stdReligion &&
                          stdReligion.map((religion) => (
                            <Option key={religion.id} value={religion.id}>
                              {religion.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Blood Group"
                      name="std_blood_group"
                      rules={[
                        {
                          required: editingFields.includes('std_blood_group') ? true : false,
                          message: "Please select blood group!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Blood Group"
                        onChange={(value) =>
                          handleSelectChange("std_blood_group", value)
                        }
                        disabled={!editingFields.includes('std_blood_group')}
                      >
                        {!!stdBloodGroup &&
                          stdBloodGroup.map((bloodGr) => (
                            <Option key={bloodGr.id} value={bloodGr.id}>
                              {bloodGr.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Nationality"
                      name="std_nationality"
                      rules={[
                        {
                          required: editingFields.includes('std_nationality') ? true : false,
                          message: "Please select nationality!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Nationality"
                        onChange={(value) =>
                          handleSelectChange("std_nationality", value)
                        }
                        disabled={!editingFields.includes('std_nationality')}
                      >
                        {!!stdNationality &&
                          stdNationality.map((nat) => (
                            <Option key={nat.id} value={nat.id}>
                              {nat.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Mother Tongue"
                      name="std_mother_tongue"
                      rules={[
                        {
                          required: editingFields.includes('std_mother_tongue') ? true : false,
                          message: "Please select mother tongue!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Mother Tongue"
                        onChange={(value) =>
                          handleSelectChange("std_mother_tongue", value)
                        }
                        disabled={!editingFields.includes('std_mother_tongue')}
                      >
                        {!!stdMotherTongue &&
                          stdMotherTongue.map((mt) => (
                            <Option key={mt.id} value={mt.id}>
                              {mt.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Type of Disability"
                      name="std_disability"
                      rules={[
                        {
                          required: editingFields.includes('std_disability') ? true : false,
                          message: "Please select type of disability!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Type of Disability"
                        onChange={(value) =>
                          handleSelectChange("std_disability", value)
                        }
                        disabled={!editingFields.includes('std_disability')}
                      >
                        {!!stdDisability &&
                          stdDisability.map((disability) => (
                            <Option key={disability.id} value={disability.id}>
                              {disability.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item
                      label="Identification Mark"
                      name="std_id_mark"
                      rules={[
                        {
                          required: editingFields.includes('std_id_mark') ? true : false,
                          message: "Please enter identification mark!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_id_mark", value)}
                        disabled={!editingFields.includes('std_id_mark')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Aadhaar Number"
                      name="std_aadhaar"
                      rules={[
                        {
                          required: editingFields.includes('std_aadhaar') ? true : false,
                          pattern: new RegExp(regExpNumber),
                          message: "Numbers only!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_aadhaar", value)}
                        disabled={!editingFields.includes('std_aadhaar')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="BPL"
                      name="std_bpl"
                      rules={[
                        {
                          required: editingFields.includes('std_bpl') ? true : false,
                          message: "Please select BPL!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select BPL"
                        onChange={(value) =>
                          handleSelectChange("std_bpl", value)
                        }
                        disabled={!editingFields.includes('std_bpl')}
                      >
                        {!!stdBpl &&
                          stdBpl.map((bpl) => (
                            <Option key={bpl.id} value={bpl.id}>
                              {bpl.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="panel-tag px-3 py-2"><strong>Contact Details</strong></div>
                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item
                      label="Address (House No. / Village / Para / Road)"
                      name="std_village"
                      rules={[
                        {
                          required: editingFields.includes('std_village') ? true : false,
                          message: "Please enter address!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_village", value)}
                        disabled={!editingFields.includes('std_village')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item
                      label="Habitation or Locality"
                      name="std_habitation_locality"
                      rules={[
                        {
                          required: editingFields.includes('std_habitation_locality') ? true : false,
                          message: "Please enter habitation or locality!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_habitation_locality", value)}
                        disabled={!editingFields.includes('std_habitation_locality')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Post Office"
                      name="std_po"
                      rules={[
                        {
                          required: editingFields.includes('std_po') ? true : false,
                          pattern: new RegExp(regExpCharacter),
                          message: "Please enter post office!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_po", value)}
                        disabled={!editingFields.includes('std_po')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Police Station"
                      name="std_ps"
                      rules={[
                        {
                          required: editingFields.includes('std_ps') ? true : false,
                          pattern: new RegExp(regExpCharacter),
                          message: "Please enter police station!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_ps", value)}
                        disabled={!editingFields.includes('std_ps')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="District"
                      name="std_district"
                      rules={[
                        {
                          required: editingFields.includes('std_district') ? true : false,
                          message: "Please select district!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select District"
                        onChange={(value) =>
                          handleDistrictChange("std_district", value)
                        }
                        disabled={!editingFields.includes('std_district')}
                      >
                        {!!stdDistrict &&
                          stdDistrict.map((dist) => (
                            <Option key={dist.id} value={dist.id}>
                              {dist.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Block/Municipality"
                      name="std_block"
                      rules={[
                        {
                          required: editingFields.includes('std_block') ? true : false,
                          message: "Please select block / municipality!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Block / Municipality"
                        onChange={(value) =>
                          handleSelectChange("std_block", value)
                        }
                        disabled={!editingFields.includes('std_block')}
                      >
                        {!!stdBlockMunicipality &&
                          stdBlockMunicipality.map((bm) => (
                            <Option key={bm.id} value={bm.id}>
                              {bm.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="PIN Code"
                      name="std_pin"
                      rules={[
                        {
                          required: editingFields.includes('std_pin') ? true : false,
                          pattern: new RegExp(regExpNumber),
                          message: "Please enter PIN code!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_pin", value)}
                        disabled={!editingFields.includes('std_pin')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Primary Mobile No."
                      name="std_contact"
                      rules={[
                        {
                          required: editingFields.includes('std_contact') ? true : false,
                          pattern: new RegExp(regExpNumber),
                          message: "Please enter primary contact number!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_contact", value)}
                        disabled={!editingFields.includes('std_contact')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Whatsapp No."
                      name="std_contact_whatsapp"
                      rules={[
                        {
                          required: editingFields.includes('std_contact_whatsapp') ? true : false,
                          pattern: new RegExp(regExpNumber),
                          message: "Please enter whatsapp number!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_contact_whatsapp", value)}
                        disabled={!editingFields.includes('std_contact_whatsapp')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="E-mail"
                      name="std_email"
                      rules={[
                        {
                          type: "email",
                          required: editingFields.includes('std_email') ? true : false,
                          message: "Please enter valid email"
                        }
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_email", value)}
                        disabled={!editingFields.includes('std_email')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="panel-tag px-3 py-2"><strong>Parent Details</strong></div>
                <Row gutter={[15]}>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Father's Name"
                      name="std_father_name"
                      rules={[
                        {
                          required: editingFields.includes('std_father_name') ? true : false,
                          pattern: new RegExp(regExpCharacter),
                          message: "Please enter father's name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_father_name", value)}
                        disabled={!editingFields.includes('std_father_name')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Father's Education"
                      name="std_father_education"
                      rules={[
                        {
                          required: editingFields.includes('std_father_education') ? true : false,
                          message: "Please select Father's Education",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Father's Education"
                        onChange={(value) =>
                          handleSelectChange("std_father_education", value)
                        }
                        disabled={!editingFields.includes('std_father_education')}
                      >
                        {!!stdEducation &&
                          stdEducation.map((edu) => (
                            <Option key={edu.id} value={edu.id}>
                              {edu.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Father's Occupation"
                      name="std_father_occu"
                      rules={[
                        {
                          required: editingFields.includes('std_father_occu') ? true : false,
                          pattern: new RegExp(regExpCharacter),
                          message: "Please enter without special character!"
                        }
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_father_occu", value)}
                        disabled={!editingFields.includes('std_father_occu')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item
                      label="Name of Office/Business & Address"
                      name="std_father_office_name"
                      rules={[
                        {
                          required: editingFields.includes('std_father_office_name') ? true : false,
                          message: "Please enter Name of Office/Business & Address!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_father_office_name", value)}
                        disabled={!editingFields.includes('std_father_office_name')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Contact No."
                      name="std_father_contact"
                      rules={[
                        {
                          required: editingFields.includes('std_father_contact') ? true : false,
                          pattern: new RegExp(regExpNumber),
                          message: "Please enter father's contact number!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_father_contact", value)}
                        disabled={!editingFields.includes('std_father_contact')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Annual Income"
                      name="std_father_income"
                      rules={[
                        {
                          required: editingFields.includes('std_father_income') ? true : false,
                          message: "Please select Annual Income!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Father's Annual Income"
                        onChange={(value) =>
                          handleSelectChange("std_father_income", value)
                        }
                        disabled={!editingFields.includes('std_father_income')}
                      >
                        {!!annualIncome &&
                          annualIncome.map((income) => (
                            <Option key={income.id} value={income.id}>
                              {income.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Mother's Name"
                      name="std_mother_name"
                      rules={[
                        {
                          required: editingFields.includes('std_mother_name') ? true : false,
                          pattern: new RegExp(regExpCharacter),
                          message: "Please enter mother's name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_mother_name", value)}
                        disabled={!editingFields.includes('std_mother_name')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Mother's Education"
                      name="std_mother_education"
                      rules={[
                        {
                          required: editingFields.includes('std_mother_education') ? true : false,
                          message: "Please select Mother's Education!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Mother's Education"
                        onChange={(value) =>
                          handleSelectChange("std_mother_education", value)
                        }
                        disabled={!editingFields.includes('std_mother_education')}
                      >
                        {!!stdEducation &&
                          stdEducation.map((edu) => (
                            <Option key={edu.id} value={edu.id}>
                              {edu.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Mother's Occupation"
                      name="std_mother_occu"
                      rules={[
                        {
                          required: editingFields.includes('std_mother_occu') ? true : false,
                          pattern: new RegExp(regExpCharacter),
                          message: "Please enter without special character!"
                        }
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_mother_occu", value)}
                        disabled={!editingFields.includes('std_mother_occu')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item
                      label="Name of Office/Business & Address"
                      name="std_mother_office_name"
                      rules={[
                        {
                          required: editingFields.includes('std_mother_office_name') ? true : false,
                          message: "Please enter Name of Office/Business & Address!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_mother_office_name", value)}
                        disabled={!editingFields.includes('std_mother_office_name')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Contact No."
                      name="std_mother_contact"
                      rules={[
                        {
                          required: editingFields.includes('std_mother_contact') ? true : false,
                          pattern: new RegExp(regExpNumber),
                          message: "Please enter mother's contact number!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_mother_contact", value)}
                        disabled={!editingFields.includes('std_mother_contact')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Annual Income"
                      name="std_mother_income"
                      rules={[
                        {
                          required: editingFields.includes('std_mother_income') ? true : false,
                          message: "Please select Annual Income!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Mother's Annual Income"
                        onChange={(value) =>
                          handleSelectChange("std_mother_income", value)
                        }
                        disabled={!editingFields.includes('std_mother_income')}
                      >
                        {!!annualIncome &&
                          annualIncome.map((income) => (
                            <Option key={income.id} value={income.id}>
                              {income.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Guardian's Name"
                      name="std_guardian_name"
                      rules={[
                        {
                          required: editingFields.includes('std_guardian_name') ? true : false,
                          pattern: new RegExp(regExpCharacter),
                          message: "Please enter guardian's name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_guardian_name", value)}
                        disabled={!editingFields.includes('std_guardian_name')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Guardian's Education"
                      name="std_guardian_education"
                      rules={[
                        {
                          required: editingFields.includes('std_guardian_education') ? true : false,
                          message: "Please select Guardian's Education!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Guardian's Education"
                        onChange={(value) =>
                          handleSelectChange("std_guardian_education", value)
                        }
                        disabled={!editingFields.includes('std_guardian_education')}
                      >
                        {!!stdEducation &&
                          stdEducation.map((edu) => (
                            <Option key={edu.id} value={edu.id}>
                              {edu.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Guardian's Occupation"
                      name="std_guardian_occu"
                      rules={[
                        {
                          required: editingFields.includes('std_guardian_occu') ? true : false,
                          pattern: new RegExp(regExpCharacter),
                          message: "Please enter guardian's occupation!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_guardian_occu", value)}
                        disabled={!editingFields.includes('std_guardian_occu')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Relation with the Student"
                      name="std_guardian_relation"
                      rules={[
                        {
                          required: editingFields.includes('std_guardian_relation') ? true : false,
                          message: "Please enter relation with the guardian!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Relation"
                        onChange={(value) =>
                          handleSelectChange("std_guardian_relation", value)
                        }
                        disabled={!editingFields.includes('std_guardian_relation')}
                      >
                        {!!stdRelation &&
                          stdRelation.map((rel) => (
                            <Option key={rel.id} value={rel.id}>
                              {rel.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Contact No."
                      name="std_guardian_contact"
                      rules={[
                        {
                          required: editingFields.includes('std_guardian_contact') ? true : false,
                          pattern: new RegExp(regExpNumber),
                          message: "Please enter guardian's contact number!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("std_guardian_contact", value)}
                        disabled={!editingFields.includes('std_guardian_contact')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Annual Income"
                      name="std_guardian_income"
                      rules={[
                        {
                          required: editingFields.includes('std_guardian_income') ? true : false,
                          message: "Please select Annual Income!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Annual Income"
                        onChange={(value) =>
                          handleSelectChange("std_guardian_income", value)
                        }
                        disabled={!editingFields.includes('std_guardian_income')}
                      >
                        {!!annualIncome &&
                          annualIncome.map((income) => (
                            <Option key={income.id} value={income.id}>
                              {income.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="panel-tag px-3 py-2"><strong>Remarks / Comment</strong></div>
                <Row gutter={[15]}>
                  <Col xs={24} sm={24} lg={24}>
                    <Form.Item
                      label="Comment (if any)"
                      name="std_comment"
                    >
                      <TextArea
                        rows={4}
                        onChange={(value) =>
                          handleChange("std_comment", value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

              </div>

              <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() =>
                      setState({ ...state })
                    }
                    loading={btnLoading}
                    className="btn btn-primary ml-auto waves-effect waves-themed"
                  >
                    Submit & Send For Approval
                  </Button>
                </Space>
              </div>
            </Form>
            :
            checkUpdateStatus(state.profile_update_status)
          }
        </div>
      </div>
    </div>
  );
};

export default AppStudentProfileEdit;