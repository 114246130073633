import React, { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { linkIt } from 'react-linkify-it';
import { Modal, Space } from "antd";

import { postRequest } from "../../axios";
import NoticeBoardLikeList from "./NoticeBoardLikeList";
import {
  getSessionData,
  getUserType,
  ShowDocumentPreview,
} from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";
import {
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";

const NoticeBoardDetail = (props) => {
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [noticeBoardDetail, setNoticeBoardDetail] = useState(null);
  const setSpinner = useOutletContext();

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
    getNoticeBoard();
  };

  const getNoticeBoard = async () => {
    setSpinner(true);
    const response = await postRequest("get-single-notice-board-information", {
      nid: props.noticeBoardDetail.id,
      session_code: getSessionData().code,
    });
    setNoticeBoardDetail(response.data.response);
    setSpinner(false);
  };

  const deleteNoticeBoard = async () => {
    setSpinner(true);
    await postRequest("remove-noticeboard", {
      nid: props.noticeBoardDetail.id
    });

    setSpinner(false);
    SuccessNotificationMsg("Success", "Notice Board deleted successfully");

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-outline-info"
        onClick={() => showModelFunction()}
      >
        OPEN
      </button>

      <Modal
        title="Notice Board Details"
        open={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="row">
          <div className="col-md-12">
            <img
              src={noticeBoardDetail?.published_by_image}
              className="profile-image rounded-circle"
              alt="publish-by"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userIcon;
              }}
            />
            <span className="d-block">
              Posted On : <strong> {noticeBoardDetail?.posted_on}</strong>
            </span>
            <span className="d-block">
              Category :&nbsp;
              <span
                className="badge text-white"
                style={{
                  backgroundColor: noticeBoardDetail?.category_bg_color,
                }}
              >
                {noticeBoardDetail?.category}
              </span>
            </span>

            <span className="d-block">
              Posted By : <strong>{noticeBoardDetail?.published_by}</strong>
            </span>
            {getUserType() === "staff" ? (
              noticeBoardDetail?.approved ? (
                <span className="d-block mb-2">
                  Approved By : <strong>{noticeBoardDetail?.approve_by}</strong>
                </span>
              ) : (
                <>
                  {noticeBoardDetail?.is_draft ? (
                    <span className="d-block mb-2">
                      <span className="badge badge-warning">DRAFT</span>
                    </span>
                  ) : (
                    <span className="d-block mb-2">
                      Approved Status :{" "}
                      <span className="badge badge-danger">Pending</span>
                    </span>
                  )}

                  <Space>
                    <Link className="btn btn-sm btn-outline-info" to="/edit-notice-board" state={{ nid: noticeBoardDetail?.id }}>
                      Edit
                    </Link>
                    <button className="btn btn-sm btn-outline-danger ml-2" onClick={deleteNoticeBoard}>
                      <i className="fal fa-bin"></i> Delete
                    </button>
                  </Space>
                </>
              )
            ) : (
              ""
            )}

            <div className="mt-2">
              <NoticeBoardLikeList
                noticeBoardDetail={noticeBoardDetail}
                hideParentModel={() => hideModelFunction()}
              />
              {noticeBoardDetail?.comment_enable ? (
                <span
                  className="text-primary mr-2 pointer"
                  onClick={() =>
                    navigate(
                      "/notice-board-comment/" + noticeBoardDetail?.id
                    )
                  }
                >
                  {getUserType() === "staff"
                    ? noticeBoardDetail?.comment_count
                    : ""}
                  &nbsp;
                  {getUserType() === "staff" ? (
                    <i
                      className={
                        noticeBoardDetail?.comment_count > 0
                          ? "fas fa-comment"
                          : "fal fa-comment"
                      }
                    ></i>
                  ) : (
                    <i
                      className={
                        noticeBoardDetail?.is_user_comment
                          ? "fas fa-comment"
                          : "fal fa-comment"
                      }
                    ></i>
                  )}
                </span>
              ) : (
                ""
              )}

              <span className="text-primary">
                {getUserType() === "staff"
                  ? noticeBoardDetail?.documents_count
                  : ""}{" "}
                <i
                  className={
                    noticeBoardDetail?.documents_count > 0
                      ? "fas fa-paperclip"
                      : "fal fa-paperclip"
                  }
                ></i>
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <p>
              Subject : <strong>{noticeBoardDetail?.subject}</strong>
            </p>
            <hr />
            <p>Description : <linkIt>{noticeBoardDetail?.description}</linkIt></p>
          </div>
        </div>
        {noticeBoardDetail?.document?.length !== 0 && (
          <>
            <hr />
            <span className="badge badge-warning">Attachment(s)</span>
            <div className="row mt-3">
              {noticeBoardDetail &&
                noticeBoardDetail.document &&
                noticeBoardDetail.document.map((doc, key) => {
                  return (
                    <div className="col-md-3" key={key}>
                      {ShowDocumentPreview(doc.file_url, doc.ext)}
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default NoticeBoardDetail;
