import React, { useState } from "react";
import { Modal } from "antd";

import { postRequest } from "../../axios";
import { getUserType, ShowDocumentPreview } from "../../utils/Helpers";
import { useOutletContext } from "react-router-dom";

const SubmittedHomeWorkDetail = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [submittedHomeworkDetail, setSubmittedHomeworkDetail] = useState(null);
  const setSpinner = useOutletContext();

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
    getHomeWork();
  };

  const getHomeWork = async () => {
    setSpinner(true);
    const response = await postRequest("get-single-submitted-homework", {
      hid: props?.homeWorkDetail?.id,
      student_id: props?.homeWorkDetail?.homework_submitted_id
    });

    if (response.data.response !== '') {
      if (getUserType() === "staff") {
        setSubmittedHomeworkDetail(response.data.response.homework_list[0]);
      } else {
        setSubmittedHomeworkDetail(response.data.response);
      }
    }
    setSpinner(false);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-success"
        onClick={() => showModelFunction()}
      >
        VIEW SUBMITTED HOMEWORK
      </button>

      <Modal
        title="Submitted Homework Details"
        open={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="row">
          <div className="col-md-4">
            <span className="d-block">
              Teacher : <strong> {submittedHomeworkDetail?.teacher_name}</strong>
            </span>
            <span className="d-block">
              Teacher's Comment :{" "}
              <strong>{submittedHomeworkDetail?.teacher_comment}</strong>
            </span>
            <span className="d-block">
              Your Description :{" "}
              <strong> {submittedHomeworkDetail?.student_description}</strong>
            </span>
          </div>
        </div>
        <hr />

        <hr />
        <span className="badge badge-warning">Attachment(s)</span>
        <div className="row mt-3">
          {submittedHomeworkDetail &&
            submittedHomeworkDetail.documents &&
            submittedHomeworkDetail.documents.map((doc) => {
              return (
                <div className="col-md-3">
                  {ShowDocumentPreview(doc.file_url, doc.ext)}
                </div>
              );
            })}

          {submittedHomeworkDetail?.documents?.length === 0 && (
            <div className="col-md-12">No attachment found.</div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SubmittedHomeWorkDetail;
