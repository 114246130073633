import React from "react";

const PageHeader = (props) => {
  return (
    <div className="subheader">
      <h1 className="subheader-title">
        {props.pageIcon}
        <span className="fw-300"> {props.pageTitle}</span>
      </h1>
    </div>
  );
};

export default PageHeader;
