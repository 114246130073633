import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Space,
} from "antd";
import { postRequest } from "../../axios";
import { getSessionData, getSchoolData, getUserData } from "../../utils/Helpers";

import { ErrorNotificationMsg, SuccessNotificationMsg, } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const StudentListFormative = (props) => {
  const formRef = useRef();
  const [tabulation, setTabulation] = useState(null);
  const [marksList, setMarksList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getTabulation();
  }, []);

  const getTabulation = async () => {
    setSpinner(true);
    const response = await postRequest("exam-marks-tabulation-formative-list", {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      indicator: props.indicator,
      subjectId: props.subjectId,
      userId: getUserData().tid,
    });

    setSpinner(false);
    if (response.data.error > 0) {
      ErrorNotificationMsg(response.data.errmsg);
    } else {
      setTabulation(response.data.response.data);
      setMarksList(response.data.response.data.students);
    }
  };

  const checkMarks = (event, student) => {
    let fm = tabulation.fullMarks;

    let mo = parseInt(event.target.value);
    if (mo !== 111 && mo !== 222 && mo !== 333) {
      if (fm < mo) {
        event.target.value = 0;
        handleMarksChange(event, student);
        ErrorNotificationMsg("Marks can not be more than " + fm);
      } else {
        SaveMarks(student);
      }
    }
  };

  const handleMarksChange = (event, student, ind) => {
    let mo = event.target.value;
    let items = [...marksList];
    let documentIndex = items.findIndex(
      (res) => res.classId === student.classId
    );
    let item = { ...items[documentIndex] };
    if (ind === "ind1") {
      item.ind1 = mo;
    }
    if (ind === "ind2") {
      item.ind2 = mo;
    }
    if (ind === "ind3") {
      item.ind3 = mo;
    }
    if (ind === "ind4") {
      item.ind4 = mo;
    }
    if (ind === "ind5") {
      item.ind5 = mo;
    }
    items[documentIndex] = item;

    setMarksList(items);
  }

  const SaveMarks = async (student) => {
    let marksArr = [];

    let items = [...marksList];
    let documentIndex = items.findIndex(
      (res) => res.classId === student.classId
    );

    let mInfo = items[documentIndex];
    marksArr.push({
      classId: mInfo.classId,
      ind1: mInfo.ind1,
      ind2: mInfo.ind2,
      ind3: mInfo.ind3,
      ind4: mInfo.ind4,
      ind5: mInfo.ind5,
    });

    const payload = {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      indicator: props.indicator,
      subjectId: props.subjectId,
      userId: getUserData().tid,
      form_data: {
        marks: marksArr
      }
    };

    try {
      const res = await postRequest("exam-marks-tabulation-formative-update", payload);

      if (res.data.error === 0) {
        // SuccessNotificationMsg("Success", "Marks successfully updated.");
        // props.handleShowList(false);
      } else {
        ErrorNotificationMsg("Sorry!! Unable to save marks.");
      }
    } catch (error) {
      ErrorNotificationMsg("Something went wrong!!");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : {props.classSection}</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                  ref={formRef}
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>ROLL</th>
                          <th>NAME</th>
                          {props.indicator === 9 ? (
                            <>
                              <th>PARTICIPATION [F.M.- {tabulation?.fullMarks}]</th>
                              <th>QUESTIONING AND EXPERIMENTATION [F.M.- {tabulation?.fullMarks}]</th>
                              <th>INTERPRETATION AND APPLICATION [F.M.- {tabulation?.fullMarks}]</th>
                              <th>EMPATHY AND COOPERATION [F.M.- {tabulation?.fullMarks}]</th>
                              <th>CREATIVE AND AESTHETIC EXPRESSION [F.M.- {tabulation?.fullMarks}]</th>
                            </>
                          )
                            :
                            <>
                              {props.indicator === 0 ?
                                <th>PARTICIPATION [F.M.- {tabulation?.fullMarks}]</th>
                                :
                                <>
                                  {props.indicator === 1 ?
                                    <th>PARTICIPATION [F.M.- {tabulation?.fullMarks}]</th>
                                    : props.indicator === 2 ?
                                      <th>QUESTIONING AND EXPERIMENTATION [F.M.- {tabulation?.fullMarks}]</th>
                                      : props.indicator === 3 ?
                                        <th>INTERPRETATION AND APPLICATION [F.M.- {tabulation?.fullMarks}]</th>
                                        : props.indicator === 4 ?
                                          <th>EMPATHY AND COOPERATION [F.M.- {tabulation?.fullMarks}]</th>
                                          : props.indicator === 5 ?
                                            <th>CREATIVE AND AESTHETIC EXPRESSION [F.M.- {tabulation?.fullMarks}]</th>
                                            : ""
                                  }
                                </>
                              }
                            </>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {marksList &&
                          marksList.map((student) => {
                            return (
                              <tr key={student?.classId}>
                                <td>{student?.stdRoll}</td>
                                <td><strong>{student?.stdName}</strong></td>
                                {props.indicator === 9 ? (
                                  <>
                                    <td>
                                      {tabulation?.lockTabulation === 1 ?
                                        <Input
                                          type="text"
                                          name="ind1"
                                          value={student?.ind1}
                                          disabled={true}
                                        />
                                        :
                                        <Input
                                          type="text"
                                          name="ind1"
                                          value={student?.ind1}
                                          id={student?.classId}
                                          onBlur={(event) => checkMarks(event, student)}
                                          onChange={(event) => handleMarksChange(event, student, "ind1")}
                                        />
                                      }
                                    </td>
                                    <td>
                                      {tabulation?.lockTabulation === 1 ?
                                        <Input
                                          type="text"
                                          name="ind2"
                                          value={student?.ind2}
                                          disabled={true}
                                        />
                                        :
                                        <Input
                                          type="text"
                                          name="ind2"
                                          value={student?.ind2}
                                          id={student?.classId}
                                          onBlur={(event) => checkMarks(event, student)}
                                          onChange={(event) => handleMarksChange(event, student, "ind2")}
                                        />
                                      }
                                    </td>
                                    <td>
                                      {tabulation?.lockTabulation === 1 ?
                                        <Input
                                          type="text"
                                          name="ind3"
                                          value={student?.ind3}
                                          disabled={true}
                                        />
                                        :
                                        <Input
                                          type="text"
                                          name="ind3"
                                          value={student?.ind3}
                                          id={student?.classId}
                                          onBlur={(event) => checkMarks(event, student)}
                                          onChange={(event) => handleMarksChange(event, student, "ind3")}
                                        />
                                      }
                                    </td>
                                    <td>
                                      {tabulation?.lockTabulation === 1 ?
                                        <Input
                                          type="text"
                                          name="ind4"
                                          value={student?.ind4}
                                          disabled={true}
                                        />
                                        :
                                        <Input
                                          type="text"
                                          name="ind4"
                                          value={student?.ind4}
                                          id={student?.classId}
                                          onBlur={(event) => checkMarks(event, student)}
                                          onChange={(event) => handleMarksChange(event, student, "ind4")}
                                        />
                                      }
                                    </td>
                                    <td>
                                      {tabulation?.lockTabulation === 1 ?
                                        <Input
                                          type="text"
                                          name="ind5"
                                          value={student?.ind5}
                                          disabled={true}
                                        />
                                        :
                                        <Input
                                          type="text"
                                          name="ind5"
                                          value={student?.ind5}
                                          id={student?.classId}
                                          onBlur={(event) => checkMarks(event, student)}
                                          onChange={(event) => handleMarksChange(event, student, "ind5")}
                                        />
                                      }
                                    </td>
                                  </>
                                )
                                  :
                                  <>
                                    {props.indicator === 0 ?
                                      <td>
                                        {tabulation?.lockTabulation === 1 ?
                                          <Input
                                            type="text"
                                            name="ind1"
                                            value={student?.ind1}
                                            disabled={true}
                                          />
                                          :
                                          <Input
                                            type="text"
                                            name="ind1"
                                            value={student?.ind1}
                                            id={student?.classId}
                                            onBlur={(event) => checkMarks(event, student)}
                                            onChange={(event) => handleMarksChange(event, student, "ind1")}
                                          />
                                        }
                                      </td>
                                      :
                                      <>
                                        {props.indicator === 1 ?
                                          <td>
                                            {tabulation?.lockTabulation === 1 ?
                                              <Input
                                                type="text"
                                                name="ind1"
                                                value={student?.ind1}
                                                disabled={true}
                                              />
                                              :
                                              <Input
                                                type="text"
                                                name="ind1"
                                                value={student?.ind1}
                                                id={student?.classId}
                                                onBlur={(event) => checkMarks(event, student)}
                                                onChange={(event) => handleMarksChange(event, student, "ind1")}
                                              />
                                            }
                                          </td>
                                          : props.indicator === 2 ?
                                            <td>
                                              {tabulation?.lockTabulation === 1 ?
                                                <Input
                                                  type="text"
                                                  name="ind2"
                                                  value={student?.ind2}
                                                  disabled={true}
                                                />
                                                :
                                                <Input
                                                  type="text"
                                                  name="ind2"
                                                  value={student?.ind2}
                                                  id={student?.classId}
                                                  onBlur={(event) => checkMarks(event, student)}
                                                  onChange={(event) => handleMarksChange(event, student, "ind2")}
                                                />
                                              }
                                            </td>
                                            : props.indicator === 3 ?
                                              <td>
                                                {tabulation?.lockTabulation === 1 ?
                                                  <Input
                                                    type="text"
                                                    name="ind3"
                                                    value={student?.ind3}
                                                    disabled={true}
                                                  />
                                                  :
                                                  <Input
                                                    type="text"
                                                    name="ind3"
                                                    value={student?.ind3}
                                                    id={student?.classId}
                                                    onBlur={(event) => checkMarks(event, student)}
                                                    onChange={(event) => handleMarksChange(event, student, "ind3")}
                                                  />
                                                }
                                              </td>
                                              : props.indicator === 4 ?
                                                <td>
                                                  {tabulation?.lockTabulation === 1 ?
                                                    <Input
                                                      type="text"
                                                      name="ind4"
                                                      value={student?.ind4}
                                                      disabled={true}
                                                    />
                                                    :
                                                    <Input
                                                      type="text"
                                                      name="ind4"
                                                      value={student?.ind4}
                                                      id={student?.classId}
                                                      onBlur={(event) => checkMarks(event, student)}
                                                      onChange={(event) => handleMarksChange(event, student, "ind4")}
                                                    />
                                                  }
                                                </td>
                                                : props.indicator === 5 ?
                                                  <td>
                                                    {tabulation?.lockTabulation === 1 ?
                                                      <Input
                                                        type="text"
                                                        name="ind5"
                                                        value={student?.ind5}
                                                        disabled={true}
                                                      />
                                                      :
                                                      <Input
                                                        type="text"
                                                        name="ind5"
                                                        value={student?.ind5}
                                                        id={student?.classId}
                                                        onBlur={(event) => checkMarks(event, student)}
                                                        onChange={(event) => handleMarksChange(event, student, "ind5")}
                                                      />
                                                    }
                                                  </td>
                                                  : ""
                                        }
                                      </>
                                    }
                                  </>
                                }
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListFormative;