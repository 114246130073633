import React, { useState, useEffect } from "react";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import userIcon from "../../images/userIcon.jpg";
import { Link, useOutletContext } from "react-router-dom";
import { Space } from "antd";

const StaffProfile = () => {
  const [staffProfile, setStaffProfile] = useState([]);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getStaffProfile();
  }, []);

  const getStaffProfile = async () => {
    setSpinner(true);
    const response = await postRequest("get-staff-personal-details");
    setStaffProfile(response.data.response);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="My Profile"
        pageIcon={<i className="subheader-icon fal fa-user"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>My Profile</h2>
              {staffProfile.profile_update_status === 1 ? (
                <div className="panel-toolbar">
                  <Space>
                    <Link
                      to="/staff-profile-edit"
                      className="btn btn-sm btn-success waves-effect waves-themed"
                    >
                      <i className="fal fa-edit"></i> Edit Profile
                    </Link>
                  </Space>
                </div>
              ) : ""}
              {staffProfile.profile_update_status === 2 ? (
                <div className="panel-toolbar">
                  <span className="badge badge-primary">IN REVIEW</span>
                </div>
              ) : ""}
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-2">
                    <div className="card border m-auto m-lg-0">
                      <img
                        src={staffProfile?.image_url}
                        className="img-thumbnail"
                        alt="Profile"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = userIcon;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5">
                    <table className="table table-sm table-bordered table-hover table-striped w-100">
                      <thead className="thead-themed">
                        <tr><th colSpan={2} className="text-center">PERSONAL DETAILS</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Staff's ID</td><td><strong>{staffProfile?.staff_id}</strong></td>
                        </tr>
                        <tr>
                          <td>Staff's Name </td><td><strong>{staffProfile?.staff_name}</strong></td>
                        </tr>
                        <tr>
                          <td>Designation</td><td>{staffProfile?.designation_text}</td>
                        </tr>
                        <tr>
                          <td>Father's Name</td><td>{staffProfile?.father_name}</td>
                        </tr>
                        <tr>
                          <td>Date of Birth</td><td>{staffProfile?.birth_date}</td>
                        </tr>
                        <tr>
                          <td>Gender</td><td>{staffProfile?.gender_text}</td>
                        </tr>
                        <tr>
                          <td>Caste</td><td>{staffProfile?.caste_text}</td>
                        </tr>
                        <tr>
                          <td>Blood Group</td><td>{staffProfile?.blood_group_text}</td>
                        </tr>
                        <tr>
                          <td>Religion</td><td>{staffProfile?.religion_text}</td>
                        </tr>
                        <tr>
                          <td>Marital Status </td><td>{staffProfile?.marital_status_text}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5">
                    <table className="table table-sm table-bordered table-hover table-striped w-100">
                      <thead className="thead-themed">
                        <tr><th colSpan={2} className="text-center">CONTACT DETAILS</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Address</td>
                          <td>
                            <span className="d-block">House No. - {staffProfile?.per_house_no}</span>
                            <span className="d-block">Street - {staffProfile?.per_street}</span>
                            <span className="d-block">Town / Village - {staffProfile?.per_village}</span>
                            <span className="d-block">Block / Municipality - {staffProfile?.per_block}</span>
                            <span className="d-block">Post Office - {staffProfile?.per_post_office}</span>
                            <span className="d-block">Police Station - {staffProfile?.per_police_station}</span>
                            <span className="d-block">District - {staffProfile?.per_district_text}</span>
                            <span className="d-block">PIN - {staffProfile?.per_pin}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Primary Mobile No.</td><td>{staffProfile?.contact_mobile}</td>
                        </tr>
                        <tr>
                          <td>Alt. Mobile No.</td><td>{staffProfile?.contact_mobile_alter}</td>
                        </tr>
                        <tr>
                          <td>E-mail</td><td>{staffProfile?.contact_email}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffProfile;