import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Select,
  Form,
} from "antd";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import {
  getSessionData,
} from "../../utils/Helpers";
import { useOutletContext } from "react-router-dom";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

const { Option } = Select;

const StaffMyAttendance = () => {
  const [state, setState] = useState({
    classSection: null,
    month_id: null
  });
  const [monthList, setMonthList] = useState([]);
  const [staffAttendance, setStaffAttendance] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [showList, setShowList] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getMonthList();

    handleSelectChange("month_id", moment().format("MM") + "-" + moment().format("YYYY"));
  }, []);

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });

    getAttendentList(value);
  };

  const getMonthList = async () => {
    setSpinner(true);
    const monthRes = await postRequest("get-month-by-session", {
      sessionCode: getSessionData().rcode
    });

    let monthArr = monthRes.data.response.data;
    setMonthList(monthArr);
    setSpinner(false);
  };

  const getAttendentList = async (month_id) => {
    if (month_id !== null) {
      setSpinner(true);

      try {
        const response = await postRequest("get-staff-personal-attendance", {
          sid: getSessionData().code,
          month: month_id.split("-")[0],
          mode: "mobile",
          year: month_id.split("-")[1],
        });

        if (response.data.error === 0) {
          setStaffAttendance(response.data.response);

          const resData = response.data.response;

          let dates = [];
          let startDate = moment(new Date(resData.start_date));
          let endDate = moment(new Date(resData.end_date));

          while (endDate.diff(startDate, 'days' >= 0)) {
            dates.push(startDate.format('DD-MM-YYYY'));
            startDate.add(1, 'days');
          }

          setDateList(dates);

          setShowList(true);
        } else {
          ErrorNotificationMsg(response.data.errmsg);
        }
        setSpinner(false);
      } catch (error) {
        ErrorNotificationMsg("No records found.");
        setSpinner(false);
      }
    } else {
      ErrorNotificationMsg("Please select all the fields");
    }
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="My Attendance"
        pageIcon={<i className='subheader-icon fal fa-hand-point-up'></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>My Attendance</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={[15]} className="mb-3">
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        name="month_id"
                        label="Select Month"
                        rules={[
                          {
                            required: true,
                            message: "Please select month!",
                          },
                        ]}
                      >
                        <Select placeholder="Select Month"
                          onChange={(value) =>
                            handleSelectChange("month_id", value)
                          }
                        >
                          {!!monthList &&
                            monthList.map((m, k) => (
                              <Option key={k} value={m.id + "-" + m.year}>
                                {m.month}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <div className="col-md-12">
                  {showList && (
                    <div className="row">
                      <span className="d-block">Current Session (Till Today) : &nbsp;</span>
                      <div className="alert alert-danger fade show px-3 py-2 mr-2">
                        Total Classes Held - <strong>{staffAttendance.session_total.classes_held}</strong>
                      </div>
                      <div className="alert alert-success fade show px-3 py-2 mr-2">
                        No. of Days Present - <strong>{staffAttendance.session_total.present}</strong>
                      </div>
                      <div className="alert alert-warning fade show px-3 py-2">
                        Overall Percentage - <strong>{staffAttendance.session_total.overall_percent}%</strong>
                      </div>

                      <table className="table table-sm table-bordered table-hover">
                        <thead className="thead-themed">
                          <tr className="text-center">
                            <th>DATE</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dateList &&
                            dateList.map((dt) => {
                              return (
                                <tr key={dt}>
                                  <td>{dt}</td>
                                  <td className="text-center">
                                    {staffAttendance.holiday.holidays.find(item =>
                                      item.includes(moment(dt, "DD-MM-YYYY").format("YYYY-MM-DD"))) ?
                                      <span className="badge badge-warning mr-2">H</span> : ""
                                    }
                                    {staffAttendance.holiday.month_off_day.find(item =>
                                      item.includes(moment(dt, "DD-MM-YYYY").format("YYYY-MM-DD"))) ?
                                      <span className="badge badge-secondary">OFF DAY</span> : ""
                                    }
                                    {staffAttendance.present.find(item =>
                                      item.includes(moment(dt, "DD-MM-YYYY").format("YYYY-MM-DD"))) ?
                                      <span className="badge badge-success">P</span> : ""
                                    }
                                    {staffAttendance.absent.find(item =>
                                      item.includes(moment(dt, "DD-MM-YYYY").format("YYYY-MM-DD"))) ?
                                      <span className="badge badge-danger">A</span> : ""
                                    }
                                    {staffAttendance.late.find(item =>
                                      item.includes(moment(dt, "DD-MM-YYYY").format("YYYY-MM-DD"))) ?
                                      <span className="badge badge-info">L</span> : ""
                                    }
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffMyAttendance;