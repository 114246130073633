import React, { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { linkIt } from 'react-linkify-it';
import { Modal, Space } from "antd";

import { postRequest } from "../../axios";
import ClassDiaryComment from "./ClassDiaryComment";
import ClassDiaryLikeList from "./ClassDiaryLikeList";

import { ShowDocumentPreview, getUserType } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";

import userIcon from "../../images/userIcon.jpg";

const ClassDiaryDetail = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [classDiaryDetail, setClassDiaryDetail] = useState(null);
  const setSpinner = useOutletContext();

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
    getClassDiary();
  };

  const getClassDiary = async () => {
    setSpinner(true);
    const response = await postRequest("get-single-classdiary", {
      cid: props.classDiaryDetail.id,
    });
    setClassDiaryDetail(response.data.response);
    setSpinner(false);
  };

  const deleteClassDiary = async () => {
    setSpinner(true);
    await postRequest("remove-classdiary", {
      cid: props.classDiaryDetail.id,
      status: "1",
    });
    SuccessNotificationMsg("Success", "Class Diary deleted successfully");

    setTimeout(() => {
      window.location.reload();
    }, 2000);
    setSpinner(false);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-outline-info"
        onClick={() => showModelFunction()}
      >
        OPEN
      </button>

      <Modal
        title="Class Diary Details"
        open={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="row">
          <div className="col-md-12">
            <img
              src={classDiaryDetail?.created_by_image}
              className="profile-image rounded-circle"
              alt="publish-by"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userIcon;
              }}
            />
            <span className="d-block">
              Assigned On : <strong> {classDiaryDetail?.assignment_date}</strong>
            </span>

            <div className="frame-wrap mb-2">
              <span className="d-block">
                Posted By : <strong>{classDiaryDetail?.published_by}</strong>
              </span>
              {getUserType() === "staff" ? (
                classDiaryDetail?.approved ? (
                  <span className="d-block">
                    Approved By : <strong>{classDiaryDetail?.approve_by}</strong>
                  </span>
                ) : (
                  <>
                    {classDiaryDetail?.is_draft ? (
                      <span className="d-block mb-2">
                        <span className="badge badge-warning">DRAFT</span>
                      </span>
                    ) : (
                      <span className="d-block mb-2">
                        Approved Status :{" "}
                        <span className="badge badge-danger">Pending</span>
                      </span>
                    )}

                    <Space>
                      <Link className="btn btn-sm btn-outline-info" to="/edit-class-diary" state={{ cid: classDiaryDetail?.id }}>
                        Edit
                      </Link>
                      <button className="btn btn-sm btn-outline-danger ml-2" onClick={deleteClassDiary}>
                        <i className="fal fa-bin"></i> Delete
                      </button>
                    </Space>
                  </>
                )
              ) : (
                ""
              )}
            </div>

            <span className="d-block text-primary mt-2">
              <ClassDiaryLikeList
                classDiaryDetail={classDiaryDetail}
                hideParentModel={() => hideModelFunction()}
              />

              {classDiaryDetail?.comment_enable ? (
                <ClassDiaryComment hideParentModel={() => hideModelFunction()} />
              ) : (
                ""
              )}

              {getUserType() === "staff" ? classDiaryDetail?.documents_count : ""}{" "}
              <i
                className={
                  classDiaryDetail?.documents_count > 0
                    ? "fas fa-paperclip"
                    : "fal fa-paperclip"
                }
              ></i>
            </span>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            {getUserType() === "staff" && (
              <span className="d-block">
                Class : <strong>{classDiaryDetail?.class_code}</strong>
              </span>
            )}
            <span className="d-block">
              Subject : <strong>{classDiaryDetail?.subject}</strong>
            </span>
            <span className="d-block">
              Page No. : <strong>{classDiaryDetail?.page_no}</strong>
            </span>
            <span className="d-block">
              Chapter No : <strong>{classDiaryDetail?.chapter_no}</strong>
            </span>
            <span className="d-block">
              Topic : <strong>{classDiaryDetail?.topic}</strong>
            </span>
            <hr />
            <p>Description : <linkIt>{classDiaryDetail?.description}</linkIt></p>
          </div>
        </div>
        {classDiaryDetail?.documents?.length !== 0 && (
          <>
            <hr />
            <span className="badge badge-warning">Attachment(s)</span>
            <div className="row mt-3">
              {classDiaryDetail &&
                classDiaryDetail.documents &&
                classDiaryDetail.documents.map((doc, key) => {
                  return (
                    <div className="col-md-3" key={key}>
                      {ShowDocumentPreview(doc.file_url, doc.ext)}
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ClassDiaryDetail;
