import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import LogoSmall from "../../styles/LogoSmall";
import coverBG from "../../images/cover-2-lg.png";
import userIcon from "../../images/userIcon.jpg";

import { getSchoolData, getSchoolMenu, getUserData } from "../../utils/Helpers";

const SidebarStudent = (props) => {
  const [showMenus, setShowMenus] = useState(true);

  const toggleSidebar = () => {
    props.toggleSidebar();
  };

  useEffect(() => {
    if ((getSchoolData().appPaymentDetails.paymentMode === 'online') && (getUserData().appPaidStatus === 0)) {
      setShowMenus(false);
    }
  }, [])

  return (
    <>
      <aside
        className={
          props.isSidebarOpen
            ? "page-sidebar sidebaropen"
            : "page-sidebar sidebarclose"
        }
      >
        <div className="page-logo">
          <Link to="/dashboard">
            <LogoSmall />
          </Link>

          {window.innerWidth < 860 && (
            <a href="#" onClick={toggleSidebar} className="btn btn-pills btn-danger" style={{ marginLeft: "15px" }} data-class="mobile-nav-on">
              <i className="ni ni-minify-nav" style={{ marginLeft: "-15px" }}></i>
            </a>
          )}
        </div>

        <nav id="js-primary-nav" className="primary-nav" role="navigation">
          <div className="info-card">
            <img
              src={getSchoolData()?.sch_img}
              className="profile-image rounded-circle"
              alt="School Logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userIcon;
              }}
            />
            <div className="info-card-text">
              <a className="d-flex align-items-center text-white">
                {getUserData().name}
              </a>
              <span className="d-inline-block text-truncate text-truncate-sm mt-1">
                Class :{" "}
                {getUserData().stdClass + " - " + getUserData().stdSection}
                <br />
                Roll No. : {getUserData().stdRoll}
              </span>
            </div>
            <img src={coverBG} className="cover" alt="cover" />

            <a href="#" className="pull-trigger-btn" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar" data-focus="nav_filter_input">
              <i className="fal fa-angle-down"></i>
            </a>
          </div>

          <ul id="js-nav-menu" className="nav-menu">
            <li>
              <Link onClick={toggleSidebar} to="/dashboard">
                <i className="fal fa-home"></i>
                <span className="nav-link-text">Dashboard</span>
              </Link>
            </li>

            {getSchoolMenu().indexOf("nb") !== -1 &&
              <li>
                <Link onClick={toggleSidebar} to="/notice-board">
                  <i className="fal fa-file-edit"></i>
                  <span className="nav-link-text">Notice Board</span>
                </Link>
              </li>
            }

            {showMenus &&
              <>
                {getSchoolMenu().indexOf("hw") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/home-work">
                      <i className="fal fa-book-reader"></i>
                      <span className="nav-link-text">Homework</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("cd") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/class-diary">
                      <i className="fal fa-books"></i>
                      <span className="nav-link-text">Class Diary</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("lc") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/live-class">
                      <i className="fal fa-camcorder"></i>
                      <span className="nav-link-text">Live Classes</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("att") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/student-my-attendance">
                      <i className="fal fa-hand-point-up"></i>
                      <span className="nav-link-text">My Attendance</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("ar") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/academic-report-student">
                      <i className="fal fa-clipboard-list-check"></i>
                      <span className="nav-link-text">Academic Report</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("fe") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/fees">
                      <i className="fal fa-receipt"></i>
                      <span className="nav-link-text">Fees</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("sp") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/student-profile">
                      <i className="fal fa-user"></i>
                      <span className="nav-link-text">Profile</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("al") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/almanac">
                      <i className="fal fa-book"></i>
                      <span className="nav-link-text">Almanac</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("ac") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/academic-calendar">
                      <i className="fal fa-calendar-edit"></i>
                      <span className="nav-link-text">Academic Calendar</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("tt") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/time-table">
                      <i className="fal fa-calendar-week"></i>
                      <span className="nav-link-text">Time Table</span>
                    </Link>
                  </li>
                }

                <li>
                  <Link onClick={toggleSidebar} to="/holiday-list">
                    <i className="fal fa-calendar-day"></i>
                    <span className="nav-link-text">Holiday List</span>
                  </Link>
                </li>
              </>
            }


            <li>
              <Link onClick={toggleSidebar} to="/contact-us">
                <i className="fal fa-phone-square"></i>
                <span className="nav-link-text">Contact Us</span>
              </Link>
            </li>
          </ul>
        </nav>
      </aside>
    </>
  );
};

export default SidebarStudent;
