import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

const PaymentFailed = (props) => {
  const setSpinner = useOutletContext();

  useEffect(() => {
    setSpinner(false);
  }, []);

  return (
    <main className="page-content">
      <div className="menu-on-top fixed-header smart-style-0">
        <div className="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center">
          <h1 className="page-error color-fusion-500">
            <span className="text-gradient">Oooops</span>
            <small className="fw-500">
              Something <u>went</u> wrong!
            </small>
          </h1>
          <h3 className="fw-500 mb-5 text-danger">
            Payment Failed...
          </h3>
          <Link className="btn btn-lg btn-danger" to="/fees">
            Click here to Fees page
          </Link>
        </div>
      </div>
    </main>
  );
};

export default PaymentFailed;
