import React, { useState, useEffect } from "react";
import { postRequest } from "../axios";

import PageHeader from "./common/PageHeader";
import { getSessionData } from "../utils/Helpers";
import { useOutletContext } from "react-router-dom";

const HolidayList = () => {
  const [holidayList, setHolidayList] = useState([]);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getHolidayList();
  }, []);

  const getHolidayList = async () => {
    setSpinner(true);
    const response = await postRequest("get-holiday-list", {
      session_code: getSessionData().code,
    });
    setHolidayList(response.data.response);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Holiday List"
        pageIcon={<i className="subheader-icon fal fa-calendar-day"></i>}
      />
      <div className="row">
        <div className="col-lg-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Holiday List</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered table-hover">
                    <thead className="thead-themed">
                      <tr className="text-center">
                        <th>SL. NO.</th>
                        <th>HOLIDAY NAME</th>
                        <th>TYPE</th>
                        <th>FROM DATE</th>
                        <th>TO DATE</th>
                        <th>DAY(S)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {holidayList &&
                        holidayList.length > 0 &&
                        holidayList.map((holiday, k) => {
                          return (
                            <tr key={k}>
                              <td className="text-right">{k + 1}</td>
                              <td>{holiday.holiday_name}</td>
                              <td>{holiday.holiday_type}</td>
                              <td>{holiday.from_date}</td>
                              <td>{holiday.to_date}</td>
                              <td className="text-right">{holiday.number_days}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HolidayList;
