import React, { useState } from "react";
import { Link } from "react-router-dom";

import LogoSmall from "../../styles/LogoSmall";
import coverBG from "../../images/cover-2-lg.png";
import { getUserData, getSchoolData, getSchoolMenu } from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";

const Sidebar = (props) => {
  const [menu1, setmenu1] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(false);
  const [menu4, setmenu4] = useState(false);
  const [menu5, setmenu5] = useState(false);
  const [menu51, setmenu51] = useState(false);
  const [menu52, setmenu52] = useState(false);
  const [menu7, setmenu7] = useState(false);
  const [menu8, setmenu8] = useState(false);

  const toggleSidebar = () => {
    props.toggleSidebar();
  };

  return (
    <>
      <aside
        className={
          props.isSidebarOpen
            ? "page-sidebar sidebaropen"
            : "page-sidebar sidebarclose"
        }
      >
        <div className="page-logo">
          <Link to="/dashboard">
            <LogoSmall />
          </Link>

          {window.innerWidth < 860 && (
            <a href="#" onClick={toggleSidebar} className="btn btn-pills btn-danger" style={{ marginLeft: "15px" }} data-class="mobile-nav-on">
              <i className="ni ni-minify-nav" style={{ marginLeft: "-15px" }}></i>
            </a>
          )}
        </div>

        <nav id="js-primary-nav" className="primary-nav" role="navigation">
          <div className="info-card">
            <img
              src={getSchoolData()?.sch_img}
              className="profile-image rounded-circle"
              alt="School Logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userIcon;
              }}
            />
            <div className="info-card-text">
              <a className="d-flex align-items-center text-white">
                {getUserData().name}
              </a>
            </div>
            <img src={coverBG} className="cover" alt="cover" />

            <a href="#" className="pull-trigger-btn" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar" data-focus="nav_filter_input">
              <i className="fal fa-angle-down"></i>
            </a>
          </div>

          <ul id="js-nav-menu" className="nav-menu">
            <li>
              <Link onClick={toggleSidebar} to="/dashboard">
                <i className="fal fa-home"></i>
                <span className="nav-link-text">Dashboard</span>
              </Link>
            </li>

            {getSchoolMenu().indexOf("nb") !== -1 &&
              <li
                className={menu1 ? "active open" : ""}
                onClick={() => setmenu1(!menu1)}
              >
                <a title="Notice Board">
                  <i className="fal fa-file-edit"></i>
                  <span className="nav-link-text">Notice Board</span>
                  <b className="collapse-sign"><em className={menu1 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
                </a>
                <ul>
                  <li>
                    <Link onClick={toggleSidebar} to="/create-notice-board">
                      <span className="nav-link-text">Create</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleSidebar} to="/notice-board">
                      <span className="nav-link-text">View</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {getSchoolMenu().indexOf("hw") !== -1 &&
              <li
                className={menu2 ? "active open" : ""}
                onClick={() => setmenu2(!menu2)}
              >
                <a title="Homework">
                  <i className="fal fa-book-reader"></i>
                  <span className="nav-link-text">Homework</span>
                  <b className="collapse-sign"><em className={menu2 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
                </a>
                <ul>
                  <li>
                    <Link onClick={toggleSidebar} to="/create-home-work">
                      <span className="nav-link-text">Create</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleSidebar} to="/home-work">
                      <span className="nav-link-text">View</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleSidebar} to="/approval-home-work">
                      <span className="nav-link-text">Approval List</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {getSchoolMenu().indexOf("cd") !== -1 &&
              <li
                className={menu3 ? "active open" : ""}
                onClick={() => setmenu3(!menu3)}
              >
                <a title="Class Diary">
                  <i className="fal fa-books"></i>
                  <span className="nav-link-text">Class Diary</span>
                  <b className="collapse-sign"><em className={menu3 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
                </a>
                <ul>
                  <li>
                    <Link onClick={toggleSidebar} to="/create-class-diary">
                      <span className="nav-link-text">Create</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleSidebar} to="/class-diary">
                      <span className="nav-link-text">View</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleSidebar} to="/approval-class-diary">
                      <span className="nav-link-text">Approval List</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {getSchoolMenu().indexOf("lc") !== -1 &&
              <li
                className={menu4 ? "active open" : ""}
                onClick={() => setmenu4(!menu4)}
              >
                <a title="Live Classes">
                  <i className="fal fa-camcorder"></i>
                  <span className="nav-link-text">Live Classes</span>
                  <b className="collapse-sign"><em className={menu4 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
                </a>
                <ul>
                  <li>
                    <Link onClick={toggleSidebar} to="/create-live-class">
                      <span className="nav-link-text">Create</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleSidebar} to="/live-class">
                      <span className="nav-link-text">List</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {getSchoolMenu().indexOf("att") !== -1 &&
              <li
                className={menu8 ? "active open" : ""}
                onClick={() => setmenu8(!menu8)}
              >
                <a title="Attendance">
                  <i className="fal fa-hand-point-up"></i>
                  <span className="nav-link-text">Attendance</span>
                  <b className="collapse-sign"><em className={menu8 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
                </a>
                <ul>
                  <li>
                    <Link onClick={toggleSidebar} to="/attendance-register">
                      <span className="nav-link-text">Register</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleSidebar} to="/attendance-information">
                      <span className="nav-link-text">Information</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleSidebar} to="/staff-my-attendance">
                      <span className="nav-link-text">My Attendance</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }

            <li
              className={menu5 ? "active open" : ""}
              onClick={() => setmenu5(!menu5)}
            >
              <a title="Examination">
                <i className="fal fa-clipboard-list-check"></i>
                <span className="nav-link-text">Examination</span>
                <b className="collapse-sign"><em className={menu5 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
              </a>
              <ul>

                {getSchoolMenu().indexOf("me") !== -1 &&
                  <>
                    {getSchoolData().school_type === 'wb-govt' ?
                      <li
                        className={menu5 ? "active open" : ""}
                        onClick={() => setmenu51(!menu51)}
                      >
                        <a title="Tabulation">
                          <span className="nav-link-text">Tabulation</span>
                          <b className="collapse-sign"><em className={menu1 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
                        </a>
                        <ul>
                          <li>
                            <Link onClick={toggleSidebar} to="/tabulation-summative">
                              <span className="nav-link-text">Summative [I-VIII]</span>
                            </Link>
                          </li>
                          <li>
                            <Link onClick={toggleSidebar} to="/tabulation-formative">
                              <span className="nav-link-text">Formative [I-VIII]</span>
                            </Link>
                          </li>
                          <li>
                            <Link onClick={toggleSidebar} to="/tabulation-nine-ten">
                              <span className="nav-link-text">Tabulation [IX-X]</span>
                            </Link>
                          </li>
                          <li>
                            <Link onClick={toggleSidebar} to="/tabulation-eleven-twelve">
                              <span className="nav-link-text">Tabulation [XI-XII]</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      :
                      <li
                        className={menu5 ? "active open" : ""}
                        onClick={() => setmenu51(!menu51)}
                      >
                        <a title="Tabulation">
                          <span className="nav-link-text">Tabulation</span>
                          <b className="collapse-sign"><em className={menu5 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
                        </a>
                        <ul>
                          <li>
                            <Link onClick={toggleSidebar} to="/tabulation-written-oral">
                              <span className="nav-link-text">Written / Oral</span>
                            </Link>
                          </li>
                          {getSchoolData().activityExam === 1 && (
                            <li>
                              <Link onClick={toggleSidebar} to="/tabulation-activities">
                                <span className="nav-link-text">Activities</span>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </li>
                    }
                  </>
                }

                {getSchoolMenu().indexOf("ar") !== -1 &&
                  <li
                    className={menu5 ? "active open" : ""}
                    onClick={() => setmenu52(!menu52)}
                  >
                    <a title="Report">
                      <span className="nav-link-text">Report</span>
                      <b className="collapse-sign"><em className={menu5 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
                    </a>
                    <ul>
                      <li>
                        <Link onClick={toggleSidebar} to="/academic-report">
                          <span className="nav-link-text">Academic Report</span>
                        </Link>
                      </li>
                      <li>
                        <Link onClick={toggleSidebar} to="/result-remarks">
                          <span className="nav-link-text">Result Remarks</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                }

              </ul>
            </li>

            <li
              className={menu7 ? "active open" : ""}
              onClick={() => setmenu7(!menu7)}
            >
              <a title="Profile">
                <i className="fal fa-user"></i>
                <span className="nav-link-text">Profile</span>
                <b className="collapse-sign"><em className={menu7 ? "fal fa-angle-up" : "fal fa-angle-down"}></em></b>
              </a>
              <ul>
                {getSchoolMenu().indexOf("tp") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/staff-profile">
                      <span className="nav-link-text">My Profile</span>
                    </Link>
                  </li>
                }

                {getSchoolMenu().indexOf("sp") !== -1 &&
                  <li>
                    <Link onClick={toggleSidebar} to="/staff-student-profile">
                      <span className="nav-link-text">Student Profile</span>
                    </Link>
                  </li>
                }
              </ul>
            </li>

            {getSchoolMenu().indexOf("al") !== -1 &&
              <li>
                <Link onClick={toggleSidebar} to="/almanac">
                  <i className="fal fa-book"></i>
                  <span className="nav-link-text">Almanac</span>
                </Link>
              </li>
            }

            {getSchoolMenu().indexOf("ac") !== -1 &&
              <li>
                <Link onClick={toggleSidebar} to="/academic-calendar">
                  <i className="fal fa-calendar-edit"></i>
                  <span className="nav-link-text">Academic Calendar</span>
                </Link>
              </li>
            }

            {getSchoolMenu().indexOf("tt") !== -1 &&
              <li>
                <Link onClick={toggleSidebar} to="/time-table">
                  <i className="fal fa-calendar-week"></i>
                  <span className="nav-link-text">Time Table</span>
                </Link>
              </li>
            }

            <li>
              <Link onClick={toggleSidebar} to="/holiday-list">
                <i className="fal fa-calendar-day"></i>
                <span className="nav-link-text">Holiday List</span>
              </Link>
            </li>

            <li>
              <Link onClick={toggleSidebar} to="/contact-us">
                <i className="fal fa-phone-square"></i>
                <span className="nav-link-text">Contact Us</span>
              </Link>
            </li>
          </ul>
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;