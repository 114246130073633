import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Form,
} from "antd";
import { postRequest } from "../axios";

import PageHeader from "./common/PageHeader";
import {
  getSessionData,
  getUserData
} from "../utils/Helpers";
import { getUserType } from "../utils/Helpers";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

const AcademicCalendar = () => {
  const [state, setState] = useState({
    class_section: null,
    exam_id: null,
  });
  const [monthList, setMonthList] = useState([]);
  const [calendarList, setCalendarList] = useState([]);
  const [showList, setShowList] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getMonthList();
  }, []);

  const handleSelectChange = (field, value) => {
    getCalendarList(value)
    setShowList(false);
  };

  let stdClass = "";
  if (getUserType() !== 'staff') {
    stdClass = getUserData().stdClass;
  }

  const getCalendarList = async (monthId) => {
    setSpinner(true);
    const calendarRes = await postRequest("get-academic-calendar-by-month", {
      session_code: getSessionData().code,
      user_type: getUserType(),
      std_class: stdClass,
      month_id: monthId,
    });

    setSpinner(false);
    if (calendarRes.data.response.academicCalendarList.length > 0) {
      setShowList(true);
      setCalendarList(calendarRes.data.response.academicCalendarList);
    } else {
      setShowList(false);
    }
  }

  const getMonthList = async () => {
    setSpinner(true);
    const monthRes = await postRequest("get-month-by-session", {
      sessionCode: getSessionData().rcode
    });

    let monthArr = monthRes.data.response.data;
    setMonthList(monthArr);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Academic Calendar"
        pageIcon={<i className="subheader-icon fal fa-calendar-edit"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Academic Calendar</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={[15]} className="mb-3">
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        name="month_id"
                        label="Select Month"
                        rules={[
                          {
                            required: true,
                            message: "Please select month!",
                          },
                        ]}
                      >
                        <Select placeholder="Select Month"
                          onChange={(value) =>
                            handleSelectChange("month_id", value)
                          }
                        >
                          {!!monthList &&
                            monthList.map((m, k) => (
                              <Option key={k} value={m.id}>
                                {m.month}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                {showList && (
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>DATE</th>
                          <th>EVENT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!calendarList &&
                          calendarList.map((cal, id) => {
                            return (
                              <tr key={id}>
                                <td>{cal.day}</td>
                                <td>
                                  {cal.events.map((event, ei) => {
                                    return (
                                      <div key={ei}>
                                        <span
                                          className="d-block badge text-white mb-2"
                                          style={{
                                            backgroundColor: event.backColCode,
                                          }}
                                        >
                                          {event.categoryName}
                                        </span>
                                        <span className="d-block">
                                          <i className="fal fa-lg fa-angle-double-right text-warning"></i>&nbsp;{event.description}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicCalendar;