import React, { useState, useRef } from "react";
import {
  Modal,
  Form,
  Button,
  DatePicker,
  Col,
  Row,
  Radio,
  Select,
  Space,
} from "antd";

import { postRequest } from "../../axios";
import { getSessionData, getUserData, getUserType } from "../../utils/Helpers";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

const HomeWorkFilter = (props) => {
  const formRef = useRef();
  const dateFormat = "DD/MM/YYYY";
  const [state, setState] = useState({
    comment: null,
  });
  const [showModel, setShowModel] = useState(false);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const setSpinner = useOutletContext();

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);

    if (getUserType() !== "staff") {
      loadSubjects();
    } else {
      getClassList();
    }
  };

  const loadSubjects = () => {
    handleClassChange(
      "class_code",
      getUserData().stdClass + "-" + getUserData().stdSection
    );
  };

  const getClassList = async () => {
    setSpinner(true);
    const classRes = await postRequest("get-teacher-class-subject", {
      session_code: getSessionData().code,
      tid: getUserData().tid,
    });

    let classArr = classRes.data.response.as_class_teacher.concat(
      classRes.data.response.as_subject_teacher
    );
    let uniqueClassList = classArr.filter(
      (item, pos) => classArr.indexOf(item) === pos
    );
    setClassList(uniqueClassList);
    setSpinner(false);
  };

  const handleClassChange = async (field, value) => {
    if (value !== undefined && value !== "") {
      setState({ ...state, [field]: value, subject: [] });
      
      let classCode = value;
      if (getUserType() === "staff") {
        formRef.current.setFieldsValue({ subject: [] });
      } else {
        classCode = value.split("-")[0];
      }

      setSpinner(true);
      const subjectRes = await postRequest("get-homework-subject-by-class-code", {
        sid: getSessionData().code,
        class_code: classCode
      });

      if (subjectRes.data.error === 0) {
        setSubjectList(subjectRes.data.response);
      }
      props.handleFilterSelectChange(field, value);
      setSpinner(false);
    }
  };

  const onFinish = async () => {
    props.applyFilter();
    setShowModel(false);
  };

  const onReset = () => {
    window.location.href = "/home-work";
  };

  return (
    <>
      <span onClick={() => showModelFunction()} style={{ float: "right" }}>
        <button
          className={
            props.filterApply
              ? "btn btn-sm btn-secondary waves-effect waves-themed"
              : "btn btn-sm btn-warning waves-effect waves-themed"
          }
        >
          <i className="fal fa-filter"></i> Filter
        </button>
      </span>

      <Modal
        title="Homework Filter"
        open={showModel}
        onCancel={hideModelFunction}
        footer={false}
      >
        <Form
          onFinish={onFinish}
          ref={formRef}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={[15]} className="mb-3">
            <Col xs={24} sm={12} lg={12}>
              <label>Based On</label>
              <br />
              <Radio.Group onChange={props.handleFilterChangeDateType}>
                <Radio value="is_assignment">Assignment Date</Radio>
                <Radio value="is_submission">Submission Date</Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item name="filterDate" label="Date">
                <DatePicker
                  format={dateFormat}
                  onChange={props.handleFilterChangeFilterDate}
                  style={{ width: "100%" }}
                  disabled={
                    props?.filterData?.is_assignment === 0 &&
                      props?.filterData?.is_submission === 0
                      ? true
                      : false
                  }
                />
              </Form.Item>
            </Col>
            {getUserType() === "staff" && (
              <Col xs={24} sm={12} lg={12}>
                <Form.Item name="class_code" label="Class">
                  <Select
                    allowClear
                    placeholder="Select Class"
                    onChange={(value) => handleClassChange("class_code", value)}
                  >
                    {!!classList &&
                      classList.map((s) => (
                        <Option key={s} value={s}>
                          {s}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12} lg={12}>
              <Form.Item name="subject" label="Subject">
                <Select
                  allowClear
                  placeholder="Select Subject"
                  onChange={(value) =>
                    props.handleFilterSelectChange("subject", value)
                  }
                >
                  {!!subjectList &&
                    subjectList.map((s) => (
                      <Option key={s.id} value={s.name}>
                        {s.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="panel-content mt-2 d-flex flex-row justify-content-end">
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                className="btn btn-primary ml-auto waves-effect waves-themed"
              >
                Apply Filter
              </Button>
              <button
                onClick={onReset}
                className="btn btn-secondary ml-auto waves-effect waves-themed"
              >
                Clear Filter
              </button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default HomeWorkFilter;
