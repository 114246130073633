import React, { useState, useEffect } from "react";

import { postRequest } from "../../axios";
import { getSessionData } from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";
import StudentDetails from "./StudentDetails";
import { useOutletContext } from "react-router-dom";

const StudentList = (props) => {
  const [studentList, setStudentList] = useState(null);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getStudentList();
  }, []);

  let stdClass = props.classSection.slice(0, -2);
  let stdSection = props.classSection.slice(-1);
  const getStudentList = async () => {
    setSpinner(true);
    const response = await postRequest("get-student-list-by-class", {
      sid: getSessionData().code,
      sclass: stdClass,
      sections: stdSection,
    });
    setStudentList(response.data.response);
    setSpinner(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : {props.classSection}</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered table-hover">
                    <thead className="thead-themed">
                      <tr>
                        <th>ROLL</th>
                        <th>PHOTO</th>
                        <th>NAME</th>
                        <th>LAST ACTIVITY</th>
                        {(props.classList.indexOf(props.classSection) > -1) && (
                          <th>ACTION</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {studentList &&
                        studentList.map((student, id) => {
                          return (
                            <tr key={id}>
                              <td>{student?.roll_no}</td>
                              <td>
                                <img
                                  src={student?.image_url}
                                  alt="created-by-img"
                                  className="profile-image rounded-circle"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = userIcon;
                                  }}
                                />
                              </td>
                              <td>
                                <strong>{student?.student_name}</strong>
                                <span className="d-block">ID : {student?.std_id}</span>
                                <span className="d-block">Mobile : {student?.student_contact}</span>
                              </td>
                              <td>
                                <span className="d-block">{student?.last_activity}</span>
                                {student?.version_name && (
                                  <span className="badge border border-danger text-danger badge-pill">MOBILE <strong>{student?.version_name}</strong></span>
                                )}
                              </td>
                              {(props.classList.indexOf(props.classSection) > -1) && (
                                <td>
                                  <StudentDetails
                                    sid={student?.student_id}
                                    history={props.history}
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentList;