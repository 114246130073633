import React, { useState, useEffect } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";

import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import NoticeBoardDetail from "./NoticeBoardDetail";
import NoticeBoardLikeList from "./NoticeBoardLikeList";
import NoticeBoardFilter from "./NoticeBoardFilter";
import { getSessionData, getUserType } from "../../utils/Helpers";

import userIcon from "../../images/userIcon.jpg";

const NoticeBoard = (props) => {
  const navigate = useNavigate();
  const [noticeBoardList, setNoticeBoardList] = useState([]);
  const [filterApply, setFilterApply] = useState(false);
  const [paginationData, setPaginationData] = useState({
    page: 1,
  });
  const setSpinner = useOutletContext();

  const initFilter = {
    filter_date: "",
    category: "",
  };
  const [filterData, setFilterData] = useState(initFilter);

  useEffect(
    (props) => {
      getNoticeBoardList(1, true);
    },
    [props]
  );

  const getNoticeBoardList = async (page, refresh = false) => {
    setSpinner(true);
    if (refresh) {
      setFilterApply(false);
      setFilterData(initFilter);
      let response = await postRequest("get-all-notice-board-information", {
        scode: getSessionData().code,
        page: page,
        ...initFilter,
      });
      setNoticeBoardList(
        response.data.response && response.data.response.noticeinfo
          ? response.data.response.noticeinfo
          : []
      );
      setPaginationData(response.data.paginationData);
    } else {
      let response = await postRequest("get-all-notice-board-information", {
        scode: getSessionData().code,
        page: page,
        ...filterData,
      });
      setNoticeBoardList(
        response.data.response && response.data.response.noticeinfo
          ? response.data.response.noticeinfo
          : []
      );
      setPaginationData(response.data.paginationData);
    }
    setSpinner(false);
  };

  const handlePrevPage = () => {
    getNoticeBoardList(paginationData.current - 1);
  };

  const handleNextPage = () => {
    getNoticeBoardList(paginationData.current + 1);
  };

  const handleFilterChangeFilterDate = (date, dateString) => {
    setFilterData({
      ...filterData,
      filter_date: date !== null ? moment(date).format("YYYY-MM-DD") : "",
    });
  };

  const handleFilterSelectChange = (field, value) => {
    setFilterData({
      ...filterData,
      [field]: value !== undefined && value !== "" ? value : "",
    });
  };

  const applyFilter = () => {
    setFilterApply(true);
    getNoticeBoardList(1);
  };

  const resetFilter = () => {
    // setFilterData({
    //   filter_date: "",
    //   category: "",
    // });
    // getNoticeBoardList(1);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Notice Board"
        pageIcon={<i className="subheader-icon fal fa-file-edit"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Notice Board</h2>{" "}
              <div className="panel-toolbar">
                {getUserType() === "staff" && (
                  <Link
                    to="/create-notice-board"
                    className="btn btn-sm btn-primary waves-effect waves-themed mr-2"
                  >
                    <i className="fal fa-plus"></i> Create Notice
                  </Link>
                )}
                <NoticeBoardFilter
                  handleFilterChangeFilterDate={handleFilterChangeFilterDate}
                  handleFilterSelectChange={handleFilterSelectChange}
                  applyFilter={applyFilter}
                  resetFilter={resetFilter}
                  filterApply={filterApply}
                />
              </div>
            </div>
            <div className="panel-container">
              <div className="panel-content">
                {noticeBoardList &&
                  noticeBoardList.map((noticeBoard) => {
                    return (
                      <div className="card border mb-2" key={noticeBoard.id}>
                        <div className="card-body">
                          <img
                            src={noticeBoard.published_by_image}
                            className="profile-image rounded-circle"
                            alt="publish-by"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = userIcon;
                            }}
                          />{" "}
                          <span className="badge card-title">
                            {" "}
                            <strong>{noticeBoard.subject}</strong>
                          </span>
                          <br />{" "}
                          <span
                            className="badge text-white "
                            style={{
                              backgroundColor: noticeBoard.category_bg_color,
                            }}
                          >
                            {noticeBoard.category}
                          </span>{" "}
                          <span className="d-block text-muted">
                            Posted By : {noticeBoard.published_by}
                          </span>

                          {getUserType() === "staff" ? (
                            noticeBoard?.approved ? (
                              <span className="d-block text-muted">
                                Approved By : {noticeBoard?.approve_by}
                              </span>
                            ) : (
                              noticeBoard?.is_draft ? (
                                <span className="badge badge-warning badge-pill">
                                  DRAFT
                                </span>
                              ) : (
                                <span className="badge border border-danger text-danger badge-pill">
                                  NOT APPROVED
                                </span>
                              )
                            )
                          ) : (
                            ""
                          )}

                          <div className="frame-wrap mb-2">
                            {" "}
                            <span className="d-block text-muted">
                              <i className="fal fa-lg fa-angle-double-right text-warning"></i>{" "}
                              Posted On : {noticeBoard.posted_on}
                            </span>{" "}
                          </div>
                          <NoticeBoardDetail
                            noticeBoardDetail={noticeBoard}
                            history={props.history}
                          />
                        </div>
                        <div className="card-footer text-muted py-2">
                          <NoticeBoardLikeList
                            noticeBoardDetail={noticeBoard}
                            hideParent={false}
                          />
                          {noticeBoard.comment_enable ? (
                            <span
                              className="text-primary mr-2 pointer"
                              onClick={() =>
                                navigate(
                                  "/notice-board-comment/" + noticeBoard.id
                                )
                              }
                            >
                              {getUserType() === "staff"
                                ? noticeBoard.comment_count
                                : ""}
                              &nbsp;
                              {getUserType() === "staff" ? (
                                <i
                                  className={
                                    noticeBoard.comment_count > 0
                                      ? "fas fa-comment"
                                      : "fal fa-comment"
                                  }
                                ></i>
                              ) : (
                                <i
                                  className={
                                    noticeBoard.is_user_comment
                                      ? "fas fa-comment"
                                      : "fal fa-comment"
                                  }
                                ></i>
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                          <span className="text-primary mr-2">
                            {noticeBoard.documents_count}&nbsp;
                            <i
                              className={
                                noticeBoard.documents_count > 0
                                  ? "fas fa-paperclip"
                                  : "fal fa-paperclip"
                              }
                            ></i>
                          </span>{" "}
                        </div>
                      </div>
                    );
                  })}

                {noticeBoardList && noticeBoardList.length === 0 && (
                  <div className="alert alert-warning">
                    No Notice Board List found for the selected Date /
                    Category
                  </div>
                )}

                {noticeBoardList && noticeBoardList.length > 0 && (
                  <div>
                    <div className="dataTables_wrapper mt-3">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="dataTables_info">
                            Showing{" "}
                            {paginationData?.current === 1
                              ? "1"
                              : (paginationData?.current - 1) * 10 + 1}{" "}
                            to{" "}
                            {paginationData?.current *
                              paginationData?.record_per_page >
                              paginationData?.total_record
                              ? paginationData?.total_record
                              : paginationData?.current *
                              paginationData?.record_per_page}{" "}
                            of {paginationData?.total_record} entries
                          </div>
                        </div>
                        <div className="col-md-7 right">
                          <div className="dataTables_paginate paging_simple_numbers">
                            <ul className="pagination">
                              <li
                                className={
                                  paginationData?.prev === ""
                                    ? "paginate_button page-item previous disabled"
                                    : "paginate_button page-item previous"
                                }
                              >
                                <a
                                  onClick={handlePrevPage}
                                  className="page-link"
                                >
                                  <i className="fal fa-chevron-left"></i>
                                </a>
                              </li>
                              <li
                                className={
                                  paginationData?.next === ""
                                    ? "paginate_button page-item next disabled"
                                    : "paginate_button page-item next"
                                }
                              >
                                <a
                                  onClick={handleNextPage}
                                  className="page-link"
                                >
                                  <i className="fal fa-chevron-right"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeBoard;
