import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getSchoolData, getUserData, getSchoolMenu } from "../../utils/Helpers";
// import GoogleAuth from "../authentication/GoogleAuth";

const ButtonUI = (props) => {
  const navigate = useNavigate();

  return (
    <div
      className="col-sm-4 col-xl-4 pointer"
      onClick={() => navigate(props.url)}
    >
      <div
        className={
          "p-3 " +
          props.color +
          " rounded overflow-hidden position-relative text-white mb-g"
        }
      >
        <div>
          <h3 className="display-5 d-block l-h-n m-0 fw-500 text-white">
            {props.title}
            <small className="m-0 l-h-n">{props.subtitle}</small>
          </h3>
        </div>
        <i
          className={
            props.icon +
            " position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
          }
          style={{ fontSize: "6rem" }}
        ></i>
      </div>
    </div>
  );
};

const DashboardStaff = (props) => {
  const [clientId, setClientId] = useState("");
  const setSpinner = useOutletContext();

  // useEffect(() => {
  //   if (getSchoolData().googleAuthOne) {
  //     if (getSchoolData().school_code === '1917006' || getSchoolData().school_code === '1911002') {
  //       let staffIds = [];
  //       if (getSchoolData().gocTwoStaffIds !== '') {
  //         staffIds = getSchoolData().gocTwoStaffIds.split(',');
  //       }

  //       if (staffIds.includes(getUserData().unique_id) && getSchoolData().googleAuthTwo) {
  //         const authOne = JSON.parse(getSchoolData().googleAuthTwo);
  //         setClientId(authOne.web.client_id);
  //       } else {
  //         const authOne = JSON.parse(getSchoolData().googleAuthOne);
  //         setClientId(authOne.web.client_id);
  //       }
  //     } else {
  //       const authOne = JSON.parse(getSchoolData().googleAuthOne);
  //       setClientId(authOne.web.client_id);
  //     }
  //   }

  //   setSpinner(false);
  // }, [])

  return (
    <div className="content">
      {/* <div className="row">
        {!!clientId && (
          <GoogleAuth clientId={clientId} />
        )}
      </div>
      <hr></hr> */}
      <div className="row">
        {getSchoolMenu().indexOf("nb") !== -1 &&
          <ButtonUI
            title="Notice Board"
            subtitle="Create"
            url="/create-notice-board"
            icon="fal fa-file-edit"
            color="bg-primary-800"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("hw") !== -1 &&
          <ButtonUI
            title="Homework"
            subtitle="Create"
            url="/create-home-work"
            icon="fal fa-book-reader"
            color="bg-warning-800"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("cd") !== -1 &&
          <ButtonUI
            title="Class Diary"
            subtitle="Create"
            url="/create-class-diary"
            icon="fal fa-books"
            color="bg-info-800"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("lc") !== -1 &&
          <ButtonUI
            title="Live Class"
            subtitle="Create"
            url="/create-live-class"
            icon="fal fa-camcorder"
            color="bg-success-800"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("att") !== -1 &&
          <ButtonUI
            title="Attendance"
            subtitle="Register"
            url="/attendance-register"
            icon="fal fa-hand-point-up"
            color="bg-danger-800"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("nb") !== -1 &&
          <ButtonUI
            title="Notice Board"
            subtitle="Show List"
            url="/notice-board"
            icon="fal fa-file-edit"
            color="bg-primary-300"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("hw") !== -1 &&
          <ButtonUI
            title="Homework"
            subtitle="Show List"
            url="/home-work"
            icon="fal fa-book-reader"
            color="bg-warning-400"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("cd") !== -1 &&
          <ButtonUI
            title="Class Diary"
            subtitle="Show List"
            url="/class-diary"
            icon="fal fa-books"
            color="bg-info-400"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("lc") !== -1 &&
          <ButtonUI
            title="Live Class"
            subtitle="Show List"
            url="/live-class"
            icon="fal fa-camcorder"
            color="bg-warning-600"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("att") !== -1 &&
          <ButtonUI
            title="Attendance"
            subtitle="Show List"
            url="/attendance-information"
            icon="fal fa-hand-point-up"
            color="bg-success-600"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("me") !== -1 &&
          <>
            {getSchoolData().school_type === 'wb-govt' ? (
              <>
                <ButtonUI
                  title="Summative"
                  subtitle="Tabulation [V-VIII]"
                  url="/tabulation-summative"
                  icon="fal fa-clipboard-list-check"
                  color="bg-success-500"
                  history={props.history}
                />
                <ButtonUI
                  title="Formative"
                  subtitle="Tabulation [V-VIII]"
                  url="/tabulation-formative"
                  icon="fal fa-clipboard-list-check"
                  color="bg-primary-500"
                  history={props.history}
                />
                <ButtonUI
                  title="Tabulation"
                  subtitle="[IX-X]"
                  url="/tabulation-nine-ten"
                  icon="fal fa-clipboard-list-check"
                  color="bg-warning-500"
                  history={props.history}
                />
              </>
            ) : (
              <>
                <ButtonUI
                  title="Tabulation"
                  subtitle="Activities"
                  url="/tabulation-activities"
                  icon="fal fa-clipboard-list-check"
                  color="bg-success-500"
                  history={props.history}
                />
                <ButtonUI
                  title="Tabulation"
                  subtitle="Written / Oral"
                  url="/tabulation-written-oral"
                  icon="fal fa-clipboard-list-check"
                  color="bg-danger-500"
                  history={props.history}
                />
                {getSchoolData().activityExam === 1 && (
                  <ButtonUI
                    title="Tabulation"
                    subtitle="Activities"
                    url="/tabulation-activities"
                    icon="fal fa-clipboard-list-check"
                    color="bg-success-500"
                    history={props.history}
                  />
                )}
              </>
            )}
          </>
        }

        {getSchoolMenu().indexOf("ar") !== -1 &&
          <>
            <ButtonUI
              title="Result Remarks"
              subtitle="Update"
              url="/result-remarks"
              icon="fal fa-clipboard-list-check"
              color="bg-primary-600"
              history={props.history}
            />
            <ButtonUI
              title="Academic Report"
              subtitle="Details"
              url="/academic-report"
              icon="fal fa-clipboard-list-check"
              color="bg-primary-600"
              history={props.history}
            />
          </>
        }

        <ButtonUI
          title="My Profile"
          subtitle="Details"
          url="/staff-profile"
          icon="fal fa-user"
          color="bg-info-500"
          history={props.history}
        />

        {getSchoolMenu().indexOf("al") !== -1 &&
          <ButtonUI
            title="Almanac"
            subtitle="List"
            url="/almanac"
            icon="fal fa-book"
            color="bg-success-500"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("ac") !== -1 &&
          <ButtonUI
            title="Academic Calendar"
            subtitle="List"
            url="/academic-calendar"
            icon="fal fa-book"
            color="bg-success-800"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("tt") !== -1 &&
          <ButtonUI
            title="Time Table"
            subtitle="List"
            url="/time-table"
            icon="fal fa-calendar-week"
            color="bg-danger-500"
            history={props.history}
          />
        }

        <ButtonUI
          title="Holiday List"
          subtitle="List"
          url="/holiday-list"
          icon="fal fa-calendar-day"
          color="bg-warning-500"
          history={props.history}
        />
      </div>
    </div>
  );
};

export default DashboardStaff;
