import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { Input, Row, Col, Form, Upload, Button, Space } from "antd";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import SubmitHomeworkDocumentUpload from "./SubmitHomeworkDocumentUpload";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { getSessionData } from "../../utils/Helpers";

const { TextArea } = Input;

const SubmitHomeWork = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef();

  const [state, setState] = useState({
    description: null,
    projectDocuments: [],
    desc_audio_file: {
      extension: "",
      file: "",
    },
    comment_audio_file: {
      extension: "",
      file: "",
    },
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getSingleSubmittedHomeWork();
  }, []);

  const getSingleSubmittedHomeWork = async () => {
    if (!location.state || location.state.hid === undefined) {
      navigate("/dashboard");
    }

    setSpinner(true);
    const response = await postRequest("get-single-submitted-homework", {
      hid: location.state?.hid,
      student_id: location.state?.student_id
    });

    setSpinner(false);
    if (response.data.response !== '') {
      const subHwDetails = response.data.response.homework_list[0];

      setState({
        ...state,
        description: subHwDetails.student_description,
        projectDocuments: subHwDetails.documents,
      });

      formRef.current.setFieldsValue({
        description: subHwDetails.student_description,
        projectDocuments: subHwDetails.documents,
      });
    }
  };

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const onFinish = async () => {
    setBtnLoading(true);
    setSpinner(true);

    let multifile = [];
    state.projectDocuments.map((img) => {
      if (img.file !== undefined && img.file !== "") {
        getBase64(img.file, (imageUrl) => {
          img.file = imageUrl.replace("data:", "").replace(/^.+,/, "");
        });
      } else {
        toDataURL(img.url, function (dataUrl) {
          // console.log("RESULT:", dataUrl);
          img.file = dataUrl.replace("data:", "").replace(/^.+,/, "");
        });
      }

      if (!img.extension) {
        img.extension = img.ext.split(".").pop();
      }

      multifile.push(img);
    });

    await sleep(state.projectDocuments.length * 1000);

    try {
      const res = await postRequest("add-student-submitted-homework", {
        session_code: getSessionData().code,
        hid: location.state.hid,
        description: state.description,
        image_files: multifile,
        desc_audio_file: state.desc_audio_file,
        comment_audio_file: state.comment_audio_file,
      });

      setSpinner(false);
      if (res.data.errmsg === "") {
        SuccessNotificationMsg("Homework submited successfully.");
        setBtnLoading(false);
        navigate("/home-work");
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg("Error in Homework submit.");
      }
    } catch (error) {
      setBtnLoading(false);
      setSpinner(false);
      ErrorNotificationMsg("Something went wrong!!");
    }
  };

  const handleProjectDocumentChange = (images) => {
    setState({ ...state, projectDocuments: images });
  };

  const handleDocumentDelete = (doc) => {
    if (doc.id !== undefined && doc.id !== "") {
      let documents = state.projectDocuments;
      let documentIndex = documents.findIndex((res) => res.id === doc.id);
      documents.splice(documentIndex, 1);
      setState({ ...state, projectDocuments: documents });
    } else {
      let documents = state.projectDocuments;
      let documentIndex = documents.findIndex(
        (res) => res.file.uid === doc.file.uid
      );
      documents.splice(documentIndex, 1);
      setState({ ...state, projectDocuments: documents });
    }
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Homework Submit"
        pageIcon={<i className="subheader-icon fal fa-book-reader"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Homework Submit</h2>
              <div className="panel-toolbar">
                <Space>
                  <Link
                    to="/home-work"
                    className="btn btn-sm btn-default waves-effect waves-themed"
                  >
                    <i className="fal fa-arrow-left"></i> Back
                  </Link>
                </Space>
              </div>
            </div>
            <div className="panel-container show">
              <div className="panel-content p-0">
                <Form
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                  ref={formRef}
                >
                  <div className="panel-content">
                    <Row gutter={[15]}>
                      <Col xs={24} sm={12} lg={24}>
                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter description",
                            },
                          ]}
                        >
                          <TextArea
                            rows={4}
                            onChange={(value) =>
                              handleChange("description", value)
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12} lg={24}>
                        <SubmitHomeworkDocumentUpload
                          stateValues={state}
                          handleProjectDocumentChange={
                            handleProjectDocumentChange
                          }
                          handleDocumentDelete={handleDocumentDelete}
                        />
                      </Col>
                    </Row>
                    <br />
                  </div>

                  <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={btnLoading}
                      className="btn btn-primary ml-auto waves-effect waves-themed"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitHomeWork;
