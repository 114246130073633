import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../Config";
import moment from "moment";
import {
  Input,
  Row,
  Col,
  Select,
  Form,
  Button,
  DatePicker,
  Space
} from "antd";
import { ErrorNotificationMsg, SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { useParams } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

const AppStaffProfileEdit = (props) => {
  const navigate = useNavigate();
  const { token, dbtoken, sid } = useParams();
  const dateFormat = "DD-MM-YYYY";
  const [form] = Form.useForm();

  const [state, setState] = useState({
    staff_name: null,
    birth_date: moment().format("DD-MM-YYYY"),
    joining_date: moment().format("DD-MM-YYYY"),
    education: null,
    father_name: null,
    mother_name: null,
    gender: [],
    caste: [],
    religion: [],
    blood_group: [],
    identification_mark: null,
    marital_status: [],
    spouse_name: null,
    aadhaar_card: null,
    voter_id_card: null,
    pan_card: null,

    per_house_no: null,
    per_street: null,
    per_village: null,
    per_block: null,
    per_post_office: null,
    per_police_station: null,
    per_pin: null,
    per_district: null,
    per_state: [],

    pre_house_no: null,
    pre_street: null,
    pre_village: null,
    pre_block: null,
    pre_post_office: null,
    pre_police_station: null,
    pre_pin: null,
    pre_district: [],
    pre_state: [],

    contact_mobile: null,
    contact_mobile_alter: null,
    contact_email: null,

    bank_name: null,
    branch_name: null,
    account_no: null,
    ifsc_code: null,

    previous_emp_school: null,
    previous_emp_subject: null,
    previous_emp_class: null,
    previous_emp_other: null,

    remarks: null,
    profile_update_status: null
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [editingFields, setEditingFields] = useState([]);

  const [gender, setGender] = useState([]);
  const [caste, setCaste] = useState([]);
  const [religion, setReligion] = useState([]);
  const [bloodGroup, setBloodGroup] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [perState, setPerState] = useState([]);
  const [perDistrict, setPerDistrict] = useState([]);
  const [preState, setPreState] = useState([]);
  const [preDistrict, setPreDistrict] = useState([]);

  const headers = {
    DBAuth: dbtoken,
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const getUtilityArrays = async () => {
      const response = await axios.post(Config.API_URL + "get-utility-arrays", state, { headers });

      setGender(response.data.response.genderStaff);
      setCaste(response.data.response.caste);
      setReligion(response.data.response.religion);
      setBloodGroup(response.data.response.bloodGroup);
      setMaritalStatus(response.data.response.maritalStatus);
      setPerState(response.data.response.stateCodes);
      setPerDistrict(response.data.response.district);
      setPreState(response.data.response.stateCodes);
      setPreDistrict(response.data.response.district);
    }
    getUtilityArrays();
  }, []);

  useEffect(() => {
    const getStaffProfile = async () => {
      const response = await axios.post(Config.API_URL + "get-staff-personal-details", sid, { headers });
      const resData = response.data.response;

      setState({
        staff_name: resData.staff_name,
        birth_date: resData.birth_date !== "" ? resData.birth_date : "",
        joining_date: resData.joining_date !== "" ? resData.joining_date : "",
        education: resData.education,
        father_name: resData.father_name,
        mother_name: resData.mother_name,
        gender: resData.gender,
        caste: resData.caste,
        religion: resData.religion,
        blood_group: resData.blood_group,
        identification_mark: resData.identification_mark,
        marital_status: resData.marital_status,
        spouse_name: resData.spouse_name,
        aadhaar_card: resData.aadhaar_card,
        voter_id_card: resData.voter_id_card,
        pan_card: resData.pan_card,

        per_house_no: resData.per_house_no,
        per_street: resData.per_street,
        per_village: resData.per_village,
        per_block: resData.per_block,
        per_post_office: resData.per_post_office,
        per_police_station: resData.per_police_station,
        per_pin: resData.per_pin,
        per_district: resData.per_district,
        per_state: resData.per_state,

        pre_house_no: resData.pre_house_no,
        pre_street: resData.pre_street,
        pre_village: resData.pre_village,
        pre_block: resData.pre_block,
        pre_post_office: resData.pre_post_office,
        pre_police_station: resData.pre_police_station,
        pre_pin: resData.pre_pin,
        pre_district: resData.pre_district,
        pre_state: resData.pre_state,

        contact_mobile: resData.contact_mobile,
        contact_mobile_alter: resData.contact_mobile_alter,
        contact_email: resData.contact_email,

        bank_name: resData.bank_name,
        branch_name: resData.branch_name,
        account_no: resData.account_no,
        ifsc_code: resData.ifsc_code,

        previous_emp_school: resData.previous_emp_school,
        previous_emp_subject: resData.previous_emp_subject,
        previous_emp_class: resData.previous_emp_class,
        previous_emp_other: resData.previous_emp_other,

        profile_update_status: resData.profile_update_status,
      });

      if (resData.profile_update_status === 1) {
        form.setFieldsValue({
          staff_name: resData.staff_name,
          birth_date: resData.birth_date !== "" ? moment(resData.birth_date, "DD-MM-YYYY") : "",
          joining_date: resData.joining_date !== "" ? moment(resData.joining_date, "DD-MM-YYYY") : "",
          education: resData.education,
          father_name: resData.father_name,
          mother_name: resData.mother_name,
          gender: resData.gender,
          caste: resData.caste,
          religion: resData.religion,
          blood_group: resData.blood_group,
          identification_mark: resData.identification_mark,
          marital_status: resData.marital_status,
          spouse_name: resData.spouse_name,
          aadhaar_card: resData.aadhaar_card,
          voter_id_card: resData.voter_id_card,
          pan_card: resData.pan_card,

          per_house_no: resData.per_house_no,
          per_street: resData.per_street,
          per_village: resData.per_village,
          per_block: resData.per_block,
          per_post_office: resData.per_post_office,
          per_police_station: resData.per_police_station,
          per_pin: resData.per_pin,
          per_district: resData.per_district,
          per_state: resData.per_state,

          pre_house_no: resData.pre_house_no,
          pre_street: resData.pre_street,
          pre_village: resData.pre_village,
          pre_block: resData.pre_block,
          pre_post_office: resData.pre_post_office,
          pre_police_station: resData.pre_police_station,
          pre_pin: resData.pre_pin,
          pre_district: resData.pre_district,
          pre_state: resData.pre_state,

          contact_mobile: resData.contact_mobile,
          contact_mobile_alter: resData.contact_mobile_alter,
          contact_email: resData.contact_email,

          bank_name: resData.bank_name,
          branch_name: resData.branch_name,
          account_no: resData.account_no,
          ifsc_code: resData.ifsc_code,

          previous_emp_school: resData.previous_emp_school,
          previous_emp_subject: resData.previous_emp_subject,
          previous_emp_class: resData.previous_emp_class,
          previous_emp_other: resData.previous_emp_other,
        });
      }

      getDistrict(resData.pre_state, 'pre');
    }

    getStaffProfile();
    // const getEditingFields = async () => {
    //   const response = await axios.post(Config.API_URL + "get-fields-for-editing", state, { headers });
    //   if (response.data.error === 0) {
    //     setEditingFields(response.data.response);
    //     getStudentProfile();
    //   } else {
    //     ErrorNotificationMsg(response.data.errmsg);
    //     navigate("/failed");
    //   }
    // }
    // getEditingFields();
  }, [sid, navigate]);

  const getDistrict = async (stateCode, type) => {
    const distList = await axios.post(Config.API_URL + "get-district-by-state", {
      stateCode: stateCode,
    }, { headers });

    if (type == 'pre') {
      setPreDistrict(distList.data.response);
    } else {
      setPerDistrict(distList.data.response);
    }

  }

  const handleChange = (field, event) => {
    if (field !== 'contact_email') {
      setState({ ...state, [field]: event.target.value.toUpperCase() });
      form.setFieldsValue({ [field]: event.target.value.toUpperCase() });
    } else {
      setState({ ...state, [field]: event.target.value });
      form.setFieldsValue({ [field]: event.target.value });
    }
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const handleDateChange = (field, value) => {
    setState({ ...state, [field]: moment(value, "DD-MM-YYYY").format("YYYY-MM-DD") });
    form.setFieldsValue({ [field]: value });
  };

  const handleStateChange = (field, value) => {
    setState({ ...state, [field]: value });

    if (field === 'pre_state') {
      form.setFieldsValue({ pre_district: [] });

      getDistrict(value, 'pre');
    } else {
      form.setFieldsValue({ per_district: [] });

      getDistrict(value, 'per');
    }
  };

  const copyPresentAddress = (event) => {
    event.preventDefault();

    getDistrict(state.pre_state, 'per');

    setState({
      ...state,
      per_house_no: state.pre_house_no,
      per_street: state.pre_street,
      per_village: state.pre_village,
      per_block: state.pre_block,
      per_post_office: state.pre_post_office,
      per_police_station: state.pre_police_station,
      per_pin: state.pre_pin,
      per_state: state.pre_state,
      per_district: state.pre_district,
    });

    form.setFieldsValue({
      per_house_no: state.pre_house_no,
      per_street: state.pre_street,
      per_village: state.pre_village,
      per_block: state.pre_block,
      per_post_office: state.pre_post_office,
      per_police_station: state.pre_police_station,
      per_pin: state.pre_pin,
      per_state: state.pre_state,
      per_district: state.pre_district,
    });
  };

  const checkUpdateStatus = (status) => {
    if (status === 0) {
      return (
        <div className="row p-2">
          <div className="col-md-12" >
            <div className="alert alert-info fade show px-3 py-2 mb-1 mt-1" >
              Not enabled for updation
            </div>
          </div>
        </div>
      );
    }
    if (status === 2) {
      return (
        <div className="row p-2">
          <div className="col-md-12" >
            <div className="alert alert-info fade show px-3 py-2 mb-1 mt-1" >
              Already applied. Kindly wait for verification from school authority
            </div>
          </div>
        </div>
      );
    }
    if (status === 3) {
      return (
        <div className="row p-2">
          <div className="col-md-12" >
            <div className="alert alert-info fade show px-3 py-2 mb-1 mt-1" >
              Submitted data verified
            </div>
          </div>
        </div>
      );
    }
  }

  const onFinish = async () => {
    setBtnLoading(true);
    const payload = {
      staff_name: state.staff_name,
      birth_date: state.birth_date,
      joining_date: state.joining_date,
      education: state.education,
      father_name: state.father_name,
      mother_name: state.mother_name,
      gender: state.gender,
      caste: state.caste,
      religion: state.religion,
      blood_group: state.blood_group,
      identification_mark: state.identification_mark,
      marital_status: state.marital_status,
      spouse_name: state.spouse_name,
      aadhaar_card: state.aadhaar_card,
      voter_id_card: state.voter_id_card,
      pan_card: state.pan_card,

      per_house_no: state.per_house_no,
      per_street: state.per_street,
      per_village: state.per_village,
      per_block: state.per_block,
      per_post_office: state.per_post_office,
      per_police_station: state.per_police_station,
      per_pin: state.per_pin,
      per_district: state.per_district,
      per_state: state.per_state,

      pre_house_no: state.pre_house_no,
      pre_street: state.pre_street,
      pre_village: state.pre_village,
      pre_block: state.pre_block,
      pre_post_office: state.pre_post_office,
      pre_police_station: state.pre_police_station,
      pre_pin: state.pre_pin,
      pre_district: state.pre_district,
      pre_state: state.pre_state,

      contact_mobile: state.contact_mobile,
      contact_mobile_alter: state.contact_mobile_alter,
      contact_email: state.contact_email,

      bank_name: state.bank_name,
      branch_name: state.branch_name,
      account_no: state.account_no,
      ifsc_code: state.ifsc_code,

      previous_emp_school: state.previous_emp_school,
      previous_emp_subject: state.previous_emp_subject,
      previous_emp_class: state.previous_emp_class,
      previous_emp_other: state.previous_emp_other,

      remarks: state.remarks,
    };

    try {
      const res = await axios.post(Config.API_URL + "update-staff-details", payload, { headers });

      if (res.data.response === "success") {
        SuccessNotificationMsg("Success", "Profile updated successfully.");
        setBtnLoading(false);
        navigate("/success");
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg("Error in Profile Update.");
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg("Something went wrong!!");
    }
  }

  const onFinishFailed = () => {
    ErrorNotificationMsg('Kindly enter all mandatory fields!!');
  };

  const regExpCharDotSpace = /^[a-zA-Z\.\s]*$/;
  const regExpNumber = /^\d+$/;
  const regExpCharNumber = /^[a-zA-Z\d]*$/;

  return (
    <div id="panel-1" className="panel">
      <div className="panel-container show">
        <div className="panel-content p-0">
          {state.profile_update_status === 1 ?
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              // ref={formRef}
              autoComplete="off"
              layout="vertical"
            >
              <div className="panel-content">
                <div className="panel-tag px-3 py-2"><strong>Personal Details</strong></div>
                <Row gutter={[15]}>
                  <Col xs={24} sm={8} lg={8}>
                    <Form.Item
                      label="Name"
                      name="staff_name"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter staff name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("staff_name", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} lg={4}>
                    <Form.Item
                      label="Gender"
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: "Please select gender!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Gender"
                        onChange={(value) =>
                          handleSelectChange("gender", value)
                        }
                      >
                        {!!gender &&
                          gender.map((gen) => (
                            <Option key={gen.id} value={gen.id}>
                              {gen.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      name="birth_date"
                      label="Date of Birth"
                      rules={[
                        {
                          required: true,
                          message: "Please enter date of birth!",
                        },
                      ]}
                    >
                      <DatePicker
                        allowClear={true}
                        value={moment()}
                        format={dateFormat}
                        onChange={(value) => handleDateChange("birth_date", value)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      name="joining_date"
                      label="Date of Joining"
                      rules={[
                        {
                          required: true,
                          message: "Please enter date of joining!",
                        },
                      ]}
                    >
                      <DatePicker
                        allowClear={true}
                        value={moment()}
                        format={dateFormat}
                        onChange={(value) => handleDateChange("joining_date", value)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Educational Qualification"
                      name="education"
                      rules={[
                        {
                          required: true,
                          message: "Please enter educational qualification!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("education", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Caste"
                      name="caste"
                      rules={[
                        {
                          required: true,
                          message: "Please select caste!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Caste"
                        onChange={(value) =>
                          handleSelectChange("caste", value)
                        }
                      >
                        {!!caste &&
                          caste.map((cast) => (
                            <Option key={cast.id} value={cast.id}>
                              {cast.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Religion"
                      name="religion"
                      rules={[
                        {
                          required: true,
                          message: "Please select religion!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Religion"
                        onChange={(value) =>
                          handleSelectChange("religion", value)
                        }
                      >
                        {!!religion &&
                          religion.map((reli) => (
                            <Option key={reli.id} value={reli.id}>
                              {reli.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Blood Group"
                      name="blood_group"
                      rules={[
                        {
                          required: true,
                          message: "Please select blood group!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Blood Group"
                        onChange={(value) =>
                          handleSelectChange("blood_group", value)
                        }
                      >
                        {!!bloodGroup &&
                          bloodGroup.map((bloodGr) => (
                            <Option key={bloodGr.id} value={bloodGr.id}>
                              {bloodGr.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Father's Name"
                      name="father_name"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter father's name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("father_name", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Mother's Name"
                      name="mother_name"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter mother's name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("mother_name", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Marital Status"
                      name="marital_status"
                      rules={[
                        {
                          required: true,
                          message: "Please select marital status!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Marital Status"
                        onChange={(value) =>
                          handleSelectChange("marital_status", value)
                        }
                      >
                        {!!maritalStatus &&
                          maritalStatus.map((mari) => (
                            <Option key={mari.id} value={mari.id}>
                              {mari.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Spouse Name"
                      name="spouse_name"
                      rules={[
                        {
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter spouse name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("spouse_name", value)} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Identification Mark"
                      name="identification_mark"
                    >
                      <Input onChange={(value) => handleChange("identification_mark", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Aadhaar ID"
                      name="aadhaar_card"
                      rules={[
                        { required: true, message: "Please enter aadhaar id!" },
                        { pattern: new RegExp(regExpNumber), message: "Enter only number!" },
                        { min: 12, message: "Aadhaar ID must be exact 12 digit!" },
                        { max: 12, message: "Aadhaar ID must be exact 12 digit!" },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("aadhaar_card", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Voter ID"
                      name="voter_id_card"
                      rules={[
                        {
                          required: true,
                          message: "Please enter voter id!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("voter_id_card", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="PAN"
                      name="pan_card"
                      rules={[
                        { required: true, message: "Please enter aadhaar id!" },
                        { pattern: new RegExp(regExpCharNumber), message: "Enter only characters and numbers!" },
                        { min: 10, message: "PAN must be exact 10 digit!" },
                        { max: 10, message: "PAN must be exact 10 digit!" },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("pan_card", value)} />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="panel-tag px-3 py-2"><strong>Contact Details</strong></div>
                <span className="d-block fw-700 text-capitalize">Present Address</span>
                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="House No."
                      name="pre_house_no"
                      rules={[
                        {
                          message: "Please enter house name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("pre_house_no", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Street"
                      name="pre_street"
                      rules={[
                        {
                          message: "Please enter street!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("pre_street", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Town / Village"
                      name="pre_village"
                      rules={[
                        {
                          required: true,
                          message: "Please enter town / village!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("pre_village", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Block / Municipality"
                      name="pre_block"
                      rules={[
                        {
                          required: true,
                          message: "Please enter block / municipality",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("pre_block", value)} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Post Office"
                      name="pre_post_office"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter post office!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("pre_post_office", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Police Station"
                      name="pre_police_station"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter police station!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("pre_police_station", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} lg={4}>
                    <Form.Item
                      label="PIN"
                      name="pre_pin"
                      rules={[
                        {
                          required: true,
                          message: "Please enter PIN!",
                        },
                        { pattern: new RegExp(regExpNumber), message: "Enter only numbers!" },
                        // { min: 6, message: "PIN must be exact 6 digit!" },
                        // { max: 6, message: "PIN must be exact 6 digit!" },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("pre_pin", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} lg={4}>
                    <Form.Item
                      label="State"
                      name="pre_state"
                      rules={[
                        {
                          required: true,
                          message: "Please select state!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select State"
                        onChange={(value) =>
                          handleStateChange("pre_state", value)
                        }
                      >
                        {!!preState &&
                          preState.map((pres) => (
                            <Option key={pres.id} value={pres.id}>
                              {pres.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} lg={4}>
                    <Form.Item
                      label="District"
                      name="pre_district"
                      rules={[
                        {
                          required: true,
                          message: "Please select district!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select district"
                        onChange={(value) =>
                          handleSelectChange("pre_district", value)
                        }
                      >
                        {!!preDistrict &&
                          preDistrict.map((preDis) => (
                            <Option key={preDis.id} value={preDis.id}>
                              {preDis.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <button
                  className="btn btn-sm btn-success waves-effect waves-themed mb-3"
                  onClick={(event) => copyPresentAddress(event)}
                >
                  <i className="fal fa-copy"></i>  Copy Present Address Data to Permanent Address
                </button>

                <span className="d-block fw-700 text-capitalize">Permanent Address</span>
                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="House No."
                      name="per_house_no"
                      rules={[
                        {
                          message: "Please enter house name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("per_house_no", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Street"
                      name="per_street"
                      rules={[
                        {
                          message: "Please enter street!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("per_street", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Town / Village"
                      name="per_village"
                      rules={[
                        {
                          required: true,
                          message: "Please enter town / village!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("per_village", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Block / Municipality"
                      name="per_block"
                      rules={[
                        {
                          required: true,
                          message: "Please enter block / municipality",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("per_block", value)} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Post Office"
                      name="per_post_office"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter post office!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("per_post_office", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Police Station"
                      name="per_police_station"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter police station!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("per_police_station", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} lg={4}>
                    <Form.Item
                      label="PIN"
                      name="per_pin"
                      rules={[
                        {
                          required: true,
                          message: "Please enter PIN!",
                        },
                        { pattern: new RegExp(regExpNumber), message: "Enter only numbers!" },
                        // { min: 6, message: "PIN must be exact 6 digit!" },
                        // { max: 6, message: "PIN must be exact 6 digit!" },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("per_pin", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} lg={4}>
                    <Form.Item
                      label="State"
                      name="per_state"
                      rules={[
                        {
                          required: true,
                          message: "Please select state!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select State"
                        onChange={(value) =>
                          handleStateChange("per_state", value)
                        }
                      >
                        {!!perState &&
                          perState.map((pers) => (
                            <Option key={pers.id} value={pers.id}>
                              {pers.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={4} lg={4}>
                    <Form.Item
                      label="District"
                      name="per_district"
                      rules={[
                        {
                          required: true,
                          message: "Please select district!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select district"
                        onChange={(value) =>
                          handleSelectChange("per_district", value)
                        }
                      >
                        {!!perDistrict &&
                          perDistrict.map((perDis) => (
                            <Option key={perDis.id} value={perDis.id}>
                              {perDis.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Mobile No."
                      name="contact_mobile"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Mobile No.!",
                        },
                        { pattern: new RegExp(regExpNumber), message: "Enter only numbers!" },
                        { min: 10, message: "Mobile number must be exact 10 digit!" },
                        { max: 10, message: "Mobile number must be exact 10 digit!" },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("contact_mobile", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Alt. Mobile No."
                      name="contact_mobile_alter"
                      rules={[
                        { pattern: new RegExp(regExpNumber), message: "Enter only numbers!" },
                        { min: 10, message: "Mobile number must be exact 10 digit!" },
                        { max: 10, message: "Mobile number must be exact 10 digit!" },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("contact_mobile_alter", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item
                      label="E-mail ID"
                      name="contact_email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please enter email address!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("contact_email", value)} />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="panel-tag px-3 py-2"><strong>Account Details</strong></div>
                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Bank Account Number"
                      name="account_no"
                      rules={[
                        {
                          required: true,
                          message: "Please enter account number!",
                        },
                        { pattern: new RegExp(regExpNumber), message: "Enter only numbers!" },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("account_no", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Bank Name"
                      name="bank_name"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter bank name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("bank_name", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Branch Name"
                      name="branch_name"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter branch name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("branch_name", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="IFS Code"
                      name="ifsc_code"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(regExpCharNumber),
                          message: "Please enter IFS Code!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("ifsc_code", value)} />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="panel-tag px-3 py-2"><strong>Last Employment Details</strong></div>
                <Row gutter={[15]}>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Previous School Name"
                      name="previous_emp_school"
                      rules={[
                        {
                          pattern: new RegExp(regExpCharDotSpace),
                          message: "Please enter previous pchool name!",
                        },
                      ]}
                    >
                      <Input onChange={(value) => handleChange("previous_emp_school", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Subjects Taught"
                      name="previous_emp_subject"
                    >
                      <Input onChange={(value) => handleChange("previous_emp_subject", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Classes Taught"
                      name="previous_emp_class"
                    >
                      <Input onChange={(value) => handleChange("previous_emp_class", value)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} lg={6}>
                    <Form.Item
                      label="Other Details"
                      name="previous_emp_other"
                    >
                      <Input onChange={(value) => handleChange("previous_emp_other", value)} />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="panel-tag px-3 py-2"><strong>Remarks / Comment</strong></div>
                <Row gutter={[15]}>
                  <Col xs={24} sm={24} lg={24}>
                    <Form.Item
                      label="Comment (if any)"
                      name="remarks"
                    >
                      <TextArea
                        rows={4}
                        onChange={(value) =>
                          handleChange("remarks", value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() =>
                      setState({ ...state })
                    }
                    loading={btnLoading}
                    className="btn btn-primary ml-auto waves-effect waves-themed"
                  >
                    Submit & Send For Approval
                  </Button>
                </Space>
              </div>
            </Form>
            :
            checkUpdateStatus(state.profile_update_status)
          }
        </div>
      </div>
    </div>
  );
};

export default AppStaffProfileEdit;