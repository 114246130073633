import React from "react";
import { Row, Col } from "antd";

import PageHeader from "../common/PageHeader";

import DueFees from "./DueFees";
import PaidFees from "./PaidFees";

const Fees = () => {
  return (
    <div id="content">
      <PageHeader
        pageTitle="Fees"
        pageIcon={<i className="subheader-icon fal fa-receipt"></i>}
      />
      <Row gutter={[15]}>
        <Col xs={24} sm={12} lg={12}>
          <DueFees />
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <PaidFees />
        </Col>
      </Row>
    </div>
  );
};

export default Fees;
