import React, { useState, useEffect } from "react";
import { postRequest } from "../axios";
import { getSchoolData, getSessionData, getUserData, getUserType } from "../utils/Helpers";
import moment from "moment";

import PageHeader from "./common/PageHeader";
import { ErrorNotificationMsg } from "../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const TimeTable = () => {
  const [studentTimeTable, setStudentTimeTable] = useState([]);
  const [staffTimeTable, setStaffTimeTable] = useState([]);
  const setSpinner = useOutletContext();

  useEffect(() => {
    if (getUserType() !== "staff") {
      getStudentTimeTable();
    } else {
      getStaffTimeTable();
    }
  }, []);

  const getStudentTimeTable = async () => {
    setSpinner(true);
    const response = await postRequest("routine-by-class-section", {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: getUserData().stdClass + "-" + getUserData().stdSection,
      token: 123456,
    });

    setSpinner(false);
    if (response.data.error === 0 && response.data.response.status) {
      setStudentTimeTable(response.data.response.data.routineArray.dayArray);
    } else {
      ErrorNotificationMsg("Time Table not found.");
    }
  };

  const getStaffTimeTable = async () => {
    setSpinner(true);
    const response = await postRequest("routine-by-teacher", {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      staffId: getUserData().referenceId,
      token: 123456,
    });

    setSpinner(false);
    if (response.data.error === 0 && response.data.response.status) {
      setStaffTimeTable(response.data.response.data.routineArray.dayArray);
    } else {
      ErrorNotificationMsg("Time Table not found.");
    }
  };

  let presentDay = moment().format("dddd").toUpperCase();

  return (
    <div id="content">
      <PageHeader
        pageTitle="Time Table"
        pageIcon={<i className="subheader-icon fal fa-calendar-week"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Time Table</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                {getUserType() !== "staff" ?
                  <div className="row">
                    <div className="col-auto">
                      <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {studentTimeTable &&
                          studentTimeTable.length > 0 &&
                          studentTimeTable.map((student, k) => {
                            return (
                              <a className={"nav-link " + (k === 0 ? "active" : "")} id={"v-pills-" + student.day + "-tab"}
                                data-toggle="pill" href={"#v-pills-" + student.day} role="tab"
                                aria-controls={"v-pills-" + student.day} aria-selected={student.day === presentDay ? "active" : ""} key={k}>
                                <span className="badge badge-primary badge-pill">{student.day}</span>
                              </a>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col table-responsive">
                      <div className="tab-content" id="v-pills-tabContent">
                        {studentTimeTable &&
                          studentTimeTable.length > 0 &&
                          studentTimeTable.map((student, p) => {
                            return (
                              <div key={p} className={"tab-pane fade" + (student.day === presentDay ? "show active" : "")} id={"v-pills-" + student.day} role="tabpanel" aria-labelledby={"v-pills-" + student.day + "-tab"}>
                                <h3><span className="badge badge-danger badge-pill">{student.day}</span></h3>
                                {student.periods && student.periods.length > 0 ?
                                  <table className="table table-sm table-bordered table-hover">
                                    <thead className="thead-themed">
                                      <tr>
                                        <th>SL. NO.</th>
                                        <th>SUBJECT</th>
                                        <th>TEACHER</th>
                                        <th>TIME</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {student.periods !== undefined
                                        ? Object.entries(student.periods).map(
                                          (pd, i) => (
                                            <tr key={i}>
                                              <td>{i + 1}</td>
                                              <td>{pd[1].subject}</td>
                                              <td>{pd[1].teacher}</td>
                                              <td>{pd[1].timeFrom + " - " + pd[1].timeTo}</td>
                                            </tr>
                                          )
                                        )
                                        : ""}
                                    </tbody>
                                  </table>
                                  :
                                  <div className="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center">
                                    <h1 className="page-error color-fusion-500">
                                      <span className="text-gradient">Enjoy Holiday!!</span>
                                    </h1>
                                  </div>
                                }
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  :
                  <div className="row">
                    <div className="col-auto">
                      <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {staffTimeTable &&
                          staffTimeTable.length > 0 &&
                          staffTimeTable.map((staff, k) => {
                            return (
                              <a className={"nav-link " + (k === 0 ? "active" : "")} id={"v-pills-" + staff.day + "-tab"}
                                data-toggle="pill" href={"#v-pills-" + staff.day} role="tab"
                                aria-controls={"v-pills-" + staff.day} aria-selected={staff.day === presentDay ? "active" : ""} key={k}>
                                <span className="badge badge-primary badge-pill">{staff.day}</span>
                              </a>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col table-responsive">
                      <div className="tab-content" id="v-pills-tabContent">
                        {staffTimeTable &&
                          staffTimeTable.length > 0 &&
                          staffTimeTable.map((staff, p) => {
                            return (
                              <div key={p} className={"tab-pane fade" + (staff.day === presentDay ? "show active" : "")} id={"v-pills-" + staff.day} role="tabpanel" aria-labelledby={"v-pills-" + staff.day + "-tab"}>
                                <h3><span className="badge badge-danger badge-pill">{staff.day}</span></h3>
                                {staff.periods && staff.periods.length > 0 ?
                                  <table className="table table-sm table-bordered table-hover" key={p}>
                                    <thead className="thead-themed">
                                      <tr>
                                        <th>SL. NO.</th>
                                        <th>SUBJECT</th>
                                        <th>CLASS</th>
                                        <th>TIME</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {staff.periods !== undefined
                                        ? Object.entries(staff.periods).map(
                                          (pd, i) => (
                                            <tr key={i}>
                                              <td>{i + 1}</td>
                                              <td>{pd[1].subject}</td>
                                              <td>{pd[1].classSection}</td>
                                              <td>{pd[1].timeFrom + " - " + pd[1].timeTo}</td>
                                            </tr>
                                          )
                                        )
                                        : ""}
                                    </tbody>
                                  </table>
                                  :
                                  <div className="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center">
                                    <h1 className="page-error color-fusion-500">
                                      <span className="text-gradient">Enjoy Holiday!!</span>
                                    </h1>
                                  </div>
                                }
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTable;