import React, { useState, useRef } from "react";
import { Input, Modal, Form, Button } from "antd";

const { TextArea } = Input;

const ClassDiaryComment = (props) => {

  const formRef = useRef();
  const [state, setState] = useState({
    comment: null,
  });
  const [showModel, setShowModel] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
  };

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value.target.value });
  };

  const onFinish = async () => {
    setBtnLoading(true);
  };

  return (
    <>
      <span onClick={() => showModelFunction()} className="text-primary">
        {props.htmlText}
      </span>

      <Modal
        title="Comment"
        open={showModel}
        onCancel={hideModelFunction}
        footer={false}
      >
        <Form onFinish={onFinish} ref={formRef} autoComplete="off" layout="vertical">
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                label="Comment"
                name="comment"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter comment",
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  defaultValue={props?.commentText}
                  value={props?.commentText}
                  onChange={(value) => handleChange("comment", value)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="panel-content mt-2 d-flex flex-row">
            <Button
              type="primary"
              htmlType="submit"
              loading={btnLoading}
              className="btn btn-primary ml-auto waves-effect waves-themed"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ClassDiaryComment;
