import React, { useState, useEffect } from "react";
import moment from "moment";

import { postRequest } from "../../axios";
import { getUserType } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const LiveClassButton = ({ liveClassDetail }) => {
  const [startDiff, setDateState] = useState("");
  const [endDiff, setEndDateState] = useState("");
  const setSpinner = useOutletContext();

  var classStartTime =
    moment(liveClassDetail.live_class_date, "DD-MM-YYYY").format("YYYY-MM-DD") +
    " " +
    moment(liveClassDetail.start_time, "HH:mm A").format("HH:mm:ss");

  var classEndTime =
    moment(liveClassDetail.live_class_date, "DD-MM-YYYY").format("YYYY-MM-DD") +
    " " +
    moment(liveClassDetail.end_time, "HH:mm A").format("HH:mm:ss");

  useEffect(() => {
    if (liveClassDetail) {
      setInterval(() => {
        setDateState(moment().diff(classStartTime, "minutes"));
        setEndDateState(moment().diff(classEndTime, "minutes"));
      }, 1000);
    }
  }, []);

  const fillAttendance = async () => {
    setSpinner(true);

    if (getUserType() === "staff") {
      const response = await postRequest("live-class-started", {
        live_class_id: liveClassDetail.id,
        status: 1
      });

      setSpinner(false);
      if (response.data.status === "success") {
        SuccessNotificationMsg("Success", response.data.message);

        window.open(liveClassDetail?.teacher_start_url);
      } else {
        ErrorNotificationMsg(response.data.message);
      }
    } else {
      const response = await postRequest("student-live-class-activity-update", {
        live_class_id: liveClassDetail.id,
      });

      setSpinner(false);
      if (response.data.status === "success") {
        SuccessNotificationMsg("Success", "Attendance marked!!");

        window.open(liveClassDetail?.join_url);
      } else {
        ErrorNotificationMsg("Unable to mark attendance!!");
      }
    }
  };

  const renderStaffButton = (startDiff, endDiff) => {
    if (endDiff > 0) {
      return (
        <div className="mt-1">
          <span className="badge badge-danger">Class Ended</span>
        </div>
      );
    }

    if (startDiff >= -4 && endDiff <= 0) {
      return (
        <div className="mt-1">
          <a
            href="#"
            onClick={fillAttendance}
            className="btn btn-sm btn-success text-white"
          >
            Start Class
          </a>
        </div>
      );
    }

    if (startDiff < -4) {
      return (
        <div className="mt-1">
          <span className="badge badge-secondary">Class Not Ready</span>
        </div>
      );
    }
  };

  const renderStudentButton = (startDiff, endDiff) => {
    if (endDiff > 0) {
      return (
        <>
          <div className="mt-1">
            <span className="badge badge-danger mr-2">Class Ended</span>
            <span>
              {liveClassDetail.is_student_attend === 0 ? <i className="fas fa-user-check text-secondary mr-2"></i>
                : liveClassDetail.is_student_attend === 1 ? <i className="fas fa-user-check text-success mr-2"></i>
                  : <i className="fas fa-user-check text-warning mr-2"></i>
              }
            </span>
          </div>
        </>
      );
    }

    if ((startDiff >= 0 && endDiff <= 0) && liveClassDetail.is_meeting_ready === 1) {
      return (
        <div className="mt-1">
          <a
            href="#"
            onClick={fillAttendance}
            className="btn btn-sm btn-success text-white"
          >
            Join Class
          </a>
        </div>
      );
    }

    if (startDiff < 0 || liveClassDetail.is_meeting_ready === 0) {
      return (
        <div className="mt-1">
          <span className="badge badge-secondary">Class Not Ready</span>
        </div>
      );
    }
  };

  return (
    <>
      {getUserType() === "staff" && renderStaffButton(startDiff, endDiff)}

      {getUserType() !== "staff" && renderStudentButton(startDiff, endDiff)}
    </>
  );
};

export default LiveClassButton;
