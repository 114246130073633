import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import SubmittedHomeWorkBlock from "./SubmittedHomeWorkBlock";
import { getSessionData } from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";

const SubmittedHomeWork = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [homeWorkDetail, setHomeWorkDetail] = useState([]);
  const [homeWorkList, setHomeWorkList] = useState([]);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getHomeWork();
  }, []);

  const getHomeWork = async () => {
    if (!location.state || location.state.hid === undefined) {
      navigate("/dashboard");
    }

    setSpinner(true);
    const response = await postRequest("get-single-homework", {
      hid: location.state?.hid,
    });
    setHomeWorkDetail(response.data.response);
    getHomeWorkList(1);
    setTimeout(() => {
      setSpinner(false);
    }, 2000);
  };

  const getHomeWorkList = async (page) => {
    setSpinner(true);
    const response = await postRequest("get-all-submitted-homework", {
      session_code: getSessionData().code,
      hid: location.state.hid,
      page: page,
    });
    setHomeWorkList(response.data.response.homework_list);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Homework Submit"
        pageIcon={<i className="subheader-icon fal fa-book-reader"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Homework Submit</h2>
              <div className="panel-toolbar">
                <Link
                  to="/home-work"
                  className="btn btn-sm btn-default waves-effect waves-themed"
                >
                  <i className="fal fa-arrow-left"></i> Back
                </Link>
              </div>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="card border mb-2" key={homeWorkDetail.id}>
                  <div className="card-body">
                    <img
                      src={homeWorkDetail?.created_by_image}
                      alt="created-by-img"
                      className="profile-image rounded-circle"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = userIcon;
                      }}
                    />

                    <span className="badge card-title">
                      {" "}
                      <strong> {homeWorkDetail?.subject}</strong>
                    </span>
                    <br />
                    <span className="badge badge-primary">
                      {" "}
                      {homeWorkDetail?.class_code}
                    </span>
                    <span className="d-block">
                      <strong>{homeWorkDetail?.topic}</strong>
                    </span>

                    <div className="frame-wrap mb-2">
                      <span className="d-block text-muted">
                        Posted By : {homeWorkDetail?.created_by}
                      </span>

                      {homeWorkDetail?.approved ? (
                        <span className="d-block text-muted">
                          Approved By : {homeWorkDetail?.approve_by}
                        </span>
                      ) : (
                        <span className="badge border border-danger text-danger badge-pill">
                          NOT APPROVED
                        </span>
                      )}

                      <span className="d-block text-muted">
                        <i className="fal fa-lg fa-angle-double-right text-warning"></i>&nbsp;
                        Assigned On : {homeWorkDetail?.assignment_date}
                        <i className="fal fa-lg fa-angle-double-right text-warning ml-2"></i>&nbsp;
                        Submit By : {homeWorkDetail?.submission_date}
                      </span>
                    </div>
                  </div>
                </div>

                {homeWorkList &&
                  homeWorkList.map((homeWork, lid) => {
                    return (
                      <SubmittedHomeWorkBlock
                        homeWorkDetail={homeWork}
                        key={lid}
                      />
                    );
                  })}

                {homeWorkList && homeWorkList.length === 0 && (
                  <div className="alert alert-warning ">
                    No Submited Home Work Found!
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmittedHomeWork;
