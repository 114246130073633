import React, { useState, useEffect } from "react";

import { postRequest } from "../../axios";
import { getSchoolData, getSessionData } from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";
import ReportCard from "./ReportCard";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const ReportStudentList = (props) => {
  const [studentList, setStudentList] = useState(null);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getStudentList();
  }, []);

  let stdClass = props.classSection.slice(0, -2);
  let stdSection = props.classSection.slice(-1);
  const getStudentList = async () => {
    setSpinner(true);
    const response = await postRequest("exam-list-total-mask-by-class", {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      stdClass: stdClass,
      stdSection: stdSection,
      exam: props.examId,
    });
    
    setSpinner(false);
    if (response.data.error === 0) {
      setStudentList(response.data.response.list);
    } else {
      ErrorNotificationMsg(response.data.errmsg);
    }   
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : {props.classSection}</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered table-hover">
                    <thead className="thead-themed">
                      <tr className="text-center">
                        <th>ROLL</th>
                        <th>PHOTO</th>
                        <th>NAME</th>
                        <th>TOTAL M.O.</th>
                        <th>PERCENTAGE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentList &&
                        studentList.map((student, id) => {
                          return (
                            <tr key={id}>
                              <td>{student?.stdRoll}</td>
                              <td>
                                <img
                                  src={student?.stdImageUrl}
                                  alt="created-by-img"
                                  className="profile-image rounded-circle"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = userIcon;
                                  }}
                                />
                              </td>
                              <td>
                                <strong>{student?.stdName}</strong>
                                <span className="d-block">ID : {student?.stdId}</span>
                              </td>
                              <td className="text-right">{student?.totalMarks}</td>
                              <td className="text-right">{student?.percentage}</td>
                              <td className="text-center">
                                {student.cid && (
                                  <ReportCard
                                    classId={student?.cid}
                                    examId={props.examId}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportStudentList;