import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import MainRoutes from "./components/common/MainRoutes";
import 'react-image-crop/dist/ReactCrop.css';

function App() {
  return (
    <Router>
      <MainRoutes />
    </Router>
  );
}

export default App;