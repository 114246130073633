import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal, Radio, Button, Space, Input } from "antd";

import { postRequest } from "../../axios";
import { getSchoolData, getSessionData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";
import userIcon from "../../images/userIcon.jpg";

const AttendanceRegisterList = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [studentList, setStudentList] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    showModelFunction();
  }, []);


  const hideModelFunction = () => {
    setShowModel(false);
    props.setShowList(false);
  };

  const showModelFunction = () => {
    getAttendentList();
    setShowModel(true);
  };

  const getAttendentList = async () => {
    if (props.classSection !== null) {
      setSpinner(true);
      let stdClass = props.classSection.slice(0, -2);
      let stdSection = props.classSection.slice(-1);

      if (getSchoolData().attendanceType === 1) {
        const response = await postRequest("get-student-attendance-monthly", {
          sid: getSessionData().code,
          monthId: props.monthId,
          sclass: stdClass,
          sections: stdSection,
        });

        if (response.data.error === 0) {
          setStudentList(response.data.response);
        } else {
          ErrorNotificationMsg(response.data.errmsg);
          setBtnLoading(false);
          hideModelFunction();
        }
      } else {
        const response = await postRequest("get-student-attendance", {
          sid: getSessionData().code,
          attendance_date: moment().format("YYYY-MM-DD"),
          sclass: stdClass,
          sections: stdSection,
        });

        if (response.data.error === 0) {
          setStudentList(response.data.response);
        } else {
          ErrorNotificationMsg(response.data.errmsg);
          setBtnLoading(false);
          hideModelFunction();
        }
      }

      setSpinner(false);
    } else {
      ErrorNotificationMsg("Please select class section");
      hideModelFunction();
    }
  };

  const onPresentChange = (e, selectedStudent) => {
    let items = [...studentList];
    let documentIndex = items.findIndex(
      (res) => res.student_class_id === selectedStudent.student_class_id
    );
    let item = { ...items[documentIndex] };
    item.present_status = e.target.value;
    if (e.target.value === 0) {
      item.late_status = "";
    }
    items[documentIndex] = item;
    setStudentList(items);
  };

  const onLateChange = (e, selectedStudent) => {
    let items = [...studentList];
    let documentIndex = items.findIndex(
      (res) => res.student_class_id === selectedStudent.student_class_id
    );
    let item = { ...items[documentIndex] };
    if (item.present_status !== 0) {
      item.late_status = e.target.value;
    }
    items[documentIndex] = item;
    setStudentList(items);
  };

  const handleAttendanceChange = (event, selectedStudent) => {
    let items = [...studentList];
    let documentIndex = items.findIndex(
      (res) => res.student_class_id === selectedStudent.student_class_id
    );
    let item = { ...items[documentIndex] };
    item.attendance = event.target.value;
    items[documentIndex] = item;
    setStudentList(items);
  }

  const updateAttendance = async () => {
    setBtnLoading(true);
    setSpinner(true);

    if (getSchoolData().attendanceType === 1) {
      const payload = {
        monthId: props.monthId,
        data: JSON.stringify(studentList)
      };

      try {
        const res = await postRequest(
          "update-student-attendance-monthly",
          payload
        );

        setSpinner(false);
        if (res.data.error === 0) {
          SuccessNotificationMsg("Success", "Successfully updated.");
          setBtnLoading(false);
          props.setShowList(false);
        } else {
          ErrorNotificationMsg(res.data.error.errmsg);
          setBtnLoading(false);
        }
      } catch (error) {
        ErrorNotificationMsg("Error in updated attendance.");
        setBtnLoading(false);
        setSpinner(false);
      }
    } else {
      const payload = {
        attendance_date: moment().format("YYYY-MM-DD"),
        data: JSON.stringify(studentList)
      };

      try {
        const res = await postRequest(
          "update-student-attendance",
          payload
        );

        setSpinner(false);
        if (res.data.error === 0) {
          SuccessNotificationMsg("Success", "Successfully updated.");
          setBtnLoading(false);
          props.setShowList(false);
        } else {
          ErrorNotificationMsg(res.data.error.errmsg);
          setBtnLoading(false);
        }
      } catch (error) {
        ErrorNotificationMsg("Error in updated attendance.");
        setBtnLoading(false);
        setSpinner(false);
      }
    }
  };

  return (
    <>
      <Modal
        title={props.monthName ? "Student List of Class " + props.classSection + ", Month : " + props.monthName : "Student List of Class " + props.classSection}
        open={showModel}
        onCancel={hideModelFunction}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={false}
      >

        <div className="row">
          <div className="table-responsive">
            <table className="table table-sm table-bordered table-hover">
              <thead className="thead-themed">
                <tr className="text-center">
                  <th>ROLL</th>
                  <th>PHOTO</th>
                  <th>NAME</th>
                  <th>{getSchoolData().attendanceType === 1 ? "DAYS" : "STATUS"}</th>
                </tr>
              </thead>
              <tbody>
                {studentList &&
                  studentList.map((student) => {
                    return (
                      <tr key={student.student_class_id}>
                        <td className="text-right">{student.roll_no}</td>
                        <td className="text-center">
                          <img
                            src={student?.image_url}
                            alt="created-by-img"
                            className="profile-image rounded-circle"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = userIcon;
                            }}
                          />
                        </td>
                        <td>{student?.student_name}</td>
                        {getSchoolData().attendanceType === 1 ?
                          (
                            <td className="text-center">
                              <Input
                                type="text"
                                name="attendance"
                                value={student?.attendance}
                                id={student?.student_class_id}
                                onChange={(event) => handleAttendanceChange(event, student)}
                              />
                            </td>
                          )
                          :
                          (
                            <td className="text-center">
                              <Radio.Group
                                key={student.student_class_id}
                                onChange={(e) => onPresentChange(e, student)}
                                value={student?.present_status}
                              >
                                <Radio value={1}>Present</Radio>
                                <Radio>Absent</Radio>
                              </Radio.Group>
                              <Radio.Group
                                key={student.student_class_id}
                                onChange={(e) => onLateChange(e, student)}
                                value={student?.late_status}
                              >
                                <Radio value={2}>Late</Radio>
                              </Radio.Group>
                            </td>
                          )}

                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div className="col-md-12">
          <div className="panel-content d-flex flex-row justify-content-end">
            <Space>
              <button
                onClick={hideModelFunction}
                className="btn btn-default ml-auto waves-effect waves-themed"
              >
                Close
              </button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={updateAttendance}
                loading={btnLoading}
                className="btn btn-info ml-auto waves-effect waves-themed"
              >
                Update Attendance
              </Button>
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AttendanceRegisterList;