import React, { useState } from "react";
import { Modal } from "antd";

import { postRequest } from "../../axios";

import { getSessionData } from "../../utils/Helpers";
import userIcon from "../../images/userIcon.jpg";
import { useOutletContext } from "react-router-dom";

const StudentDetails = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [studentProfile, setStudentProfile] = useState(null);
  const setSpinner = useOutletContext();

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
    getStudentProfile();
  };

  const getStudentProfile = async () => {
    setSpinner(true);
    const response = await postRequest("get-student-personal-details", {
      student_id: props.sid,
    });
    setStudentProfile(response.data.response);
    setSpinner(false);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-outline-info"
        onClick={() => showModelFunction()}
      >
        VIEW PROFILE
      </button>

      <Modal
        title="Students' Profile"
        open={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-2">
            <div className="card border m-auto m-lg-0">
              <img
                src={studentProfile?.image_url}
                className="img-thumbnail"
                alt="Profile Photo"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = userIcon;
                }}
              />
            </div>
            <table className="table table-sm table-bordered table-hover table-striped w-100 mt-2">
              <thead className="thead-themed">
                <tr><th colSpan={2}>PRESENT CLASS</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Session</td><td>{getSessionData().rcode}</td>
                </tr>
                <tr>
                  <td>Class</td><td>{studentProfile?.student_class.std_class}</td>
                </tr>
                <tr>
                  <td>Section</td><td>{studentProfile?.student_class.std_section}</td>
                </tr>
                <tr>
                  <td>Roll</td><td>{studentProfile?.student_class.std_roll}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-5">
            <table className="table table-sm table-bordered table-hover table-striped w-100">
              <thead className="thead-themed">
                <tr><th colSpan={2} className="text-center">PERSONAL DETAILS</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Student's ID</td><td><strong>{studentProfile?.std_id}</strong></td>
                </tr>
                {studentProfile?.std_bs_code &&
                  <tr>
                    <td>Banglar Siksha ID</td><td><strong>{studentProfile?.std_bs_code}</strong></td>
                  </tr>
                }
                <tr>
                  <td>Student's Name </td><td><strong>{studentProfile?.std_name}</strong></td>
                </tr>
                <tr>
                  <td>Date of Birth</td><td>{studentProfile?.std_birth_date}</td>
                </tr>
                <tr>
                  <td>Gender </td><td>{studentProfile?.std_gender_text}</td>
                </tr>
                <tr>
                  <td>Caste</td><td>{studentProfile?.std_caste_text}</td>
                </tr>
                <tr>
                  <td>Blood Group</td><td>{studentProfile?.std_blood_group_text}</td>
                </tr>
                <tr>
                  <td>Second Language</td><td>{studentProfile?.second_lang}</td>
                </tr>
                <tr>
                  <td>Third Language</td><td>{studentProfile?.third_lang}</td>
                </tr>
                <tr>
                  <td>Religion</td><td>{studentProfile?.std_religion_text}</td>
                </tr>
                <tr>
                  <td>Aadhaar Card No.</td><td>{studentProfile?.std_aadhaar}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-5">
            <table className="table table-sm table-bordered table-hover table-striped w-100">
              <thead className="thead-themed">
                <tr><th colSpan={2} className="text-center">PARENT DETAILS</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Father's Name</td><td>{studentProfile?.std_father_name}</td>
                </tr>
                <tr>
                  <td>Father's Contact No.</td><td>{studentProfile?.std_father_aadhaar}</td>
                </tr>
                <tr>
                  <td>Mother's Name</td><td>{studentProfile?.std_mother_name}</td>
                </tr>
                <tr>
                  <td>Mother's Contact No.</td><td>{studentProfile?.std_mother_aadhaar}</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-sm table-bordered table-hover table-striped w-100">
              <thead className="thead-themed">
                <tr><th colSpan={2} className="text-center">CONTACT DETAILS</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Address</td>
                  <td>
                    <span className="d-block">Village - {studentProfile?.std_village}</span>
                    <span className="d-block">P.O. - {studentProfile?.std_po}</span>
                    <span className="d-block">P.S. - {studentProfile?.std_ps}</span>
                    <span className="d-block">Dist. - {studentProfile?.std_district_text}</span>
                    <span className="d-block">PIN - {studentProfile?.std_pin}</span>
                  </td>
                </tr>
                <tr>
                  <td>Primary Mobile No.</td><td>{studentProfile?.std_contact}</td>
                </tr>
                <tr>
                  <td>Whatsapp</td><td>{studentProfile?.std_contact_whatsapp}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StudentDetails;
