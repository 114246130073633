import React, { useState, useEffect } from "react";
import { Modal } from "antd";

import { postRequest } from "../../axios";
import { getUserType } from "../../utils/Helpers";
import { SuccessNotificationMsg } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const HomeWorkLikeList = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [likeList, setLikeList] = useState(null);
  const [studentLikeStatus, setStudentLikeStatus] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    if (props.homeWorkDetail) {
      setStudentLikeStatus(props.homeWorkDetail.is_user_liked);
    }
  }, [props.homeWorkDetail]);

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
    getLikeList();
  };

  const getLikeList = async () => {
    setSpinner(true);
    const response = await postRequest("get-student-like-by-type", {
      object_id: props.homeWorkDetail.id,
      type: "hw",
    });
    setLikeList(response.data.response);
    setSpinner(false);
  };

  const likeHomeWork = async () => {
    setSpinner(true);
    const likeResponse = await postRequest("add-homework-like", {
      hid: props.homeWorkDetail.id,
    });

    setSpinner(false);
    if (likeResponse.data.errmsg === "") {
      SuccessNotificationMsg("Success", "Like updated successfully!");
      setStudentLikeStatus(studentLikeStatus ? false : true);
      if (props.hideParent) {
        props?.hideParentModel();
      }
    }
  };

  return (
    <>
      <span
        className="text-primary mr-2 pointer"
        onClick={
          getUserType() === "staff"
            ? () => showModelFunction()
            : () => likeHomeWork()
        }
      >
        {props?.homeWorkDetail?.total_like}
        &nbsp;
        {getUserType() === "staff" ? (
          <i
            className={
              props?.homeWorkDetail?.total_like > 0
                ? "fas fa-thumbs-up"
                : "fal fa-thumbs-up"
            }
          ></i>
        ) : (
          <i
            className={
              studentLikeStatus ? "fas fa-thumbs-up" : "fal fa-thumbs-up"
            }
          ></i>
        )}
      </span>

      <Modal
        title="Assigned Student List"
        open={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="row">
          <div className="col-md-12">
            <span className="d-block">
              Topic : <strong>{props.homeWorkDetail?.topic}</strong>
            </span>
            <span className="d-block">
              Assignment Date :{" "}
              <strong>{props.homeWorkDetail?.assignment_date}</strong>
            </span>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-sm table-bordered table-hover">
                <thead className="thead-themed">
                  <tr className="text-center">
                    <th>SL.</th>
                    <th>NAME</th>
                    <th>CLASS</th>
                    <th>SECTION</th>
                    <th>ROLL</th>
                    <th>LIKE</th>
                  </tr>
                </thead>
                <tbody>
                  {likeList &&
                    likeList.map((student, id) => {
                      return (
                        <tr key={id}>
                          <td className="text-right">{id + 1}</td>
                          <td>
                            <i
                              className={
                                student?.is_seen
                                  ? "fas fa-eye text-primary"
                                  : "fal fa-eye text-primary"
                              }
                            ></i>{" "}
                            {student?.name}
                          </td>
                          <td className="text-center">{student?.class}</td>
                          <td className="text-center">{student?.section}</td>
                          <td className="text-right">{student?.roll}</td>
                          <td className="text-center">
                            <i
                              className={
                                student?.like
                                  ? "fas fa-thumbs-up text-primary"
                                  : "fal fa-thumbs-up text-primary"
                              }
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HomeWorkLikeList;
