import React from 'react';
import { Modal } from 'antd';
import ReactCrop from 'react-image-crop';
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

class CropModal extends React.Component {
    state = {
        myFile: null,
        image: null,
        src: null,
        crop: {
            unit: "px",
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        },
        croppedImg: null,
    };
    componentDidMount() {
        const { myFile } = { ...this.props };
        if ( myFile === null ) {
            return;
        } else {
            if ( myFile ) {
                const reader = new FileReader();
                reader.addEventListener( 'load', () => this.setState( { src: reader.result } ), false );
                reader.readAsDataURL( myFile );
            }
            this.setState( { myFile: myFile } );
        }
    };
    
    onCropComplete = async () => {
        const croppedImg = await this.getCroppedImg();
        this.setState( { croppedImg } );
    };
    getCroppedImg = () => {
        const { image, myFile, crop } = { ...this.state };
        if ( image ) {
            const canvas = document.createElement( "canvas" );
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext( "2d" );
            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;
            ctx.setTransform( pixelRatio, 0, 0, pixelRatio, 0, 0 );
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage( image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height );
            return new Promise( ( resolve, reject ) => {
                canvas.toBlob( ( blob ) => { 
                    blob.name = myFile.name; 
                    resolve( blob ); 
                }, 
                `image/${ myFile.type.split( "/" )[ 1 ] }`, 
                1 );
            } );
        }
    };
    getCompressedImage = () => {
        
    };
    render() {
        const { isModalopen, onOk, handleSubmit, onCancle } = { ...this.props };
        const { croppedImg } = { ...this.state };
        return (
            <Modal 
                title="Crop Image" width={ 1000 } 
                open={ isModalopen ? true : false } 
                onOk={ () => {
                    if ( handleSubmit ) {
                        if ( croppedImg === null || croppedImg === undefined ) {
                            ErrorNotificationMsg( "Please crop to select image" );
                        } else {
                            handleSubmit( croppedImg );
                            if ( onOk ) { onOk() }
                        }
                    }
                } } 
                onCancel={ onCancle ? onCancle : () => {} }
            >
                {this.state.src && (
                    <ReactCrop
                        src={ this.state.src } 
                        crop={ this.state.crop }
                        onComplete={this.onCropComplete} 
                        onChange={(newCrop) => this.setState({ crop: newCrop })}
                        onImageLoaded={(image) => {
                            this.setState({ image });
                        }}
                    >
                    </ReactCrop>
                )}
                {croppedImg && (
                    <div>
                        <h5>Preview:</h5>
                        <img
                            src={URL.createObjectURL(croppedImg)}
                            alt="Cropped Preview"
                            style={{ maxWidth: '100%', maxHeight: '300px' }}
                        />
                    </div>
                )}
            </Modal>
        );
    }
}
 
export default CropModal;