import React, { useState } from "react";
import moment from "moment";
import { Modal, Radio, Button, Space } from "antd";

import { postRequest } from "../../axios";
import { getUserType } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const LiveClassAttendentList = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [studentList, setStudentList] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const setSpinner = useOutletContext();

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
    getAttendentList();
  };

  const getAttendentList = async () => {
    setSpinner(true);
    const response = await postRequest("student-live-class-attended-list", {
      id: props.liveClassDetail.id,
    });
    setStudentList(response.data.response.student_list);
    setSpinner(false);
  };

  const onChange = (e, selectedStudent) => {
    let items = [...studentList];
    let documentIndex = items.findIndex(
      (res) => res.student_id === selectedStudent.student_id
    );
    let item = { ...items[documentIndex] };
    item.is_meeting_attended = e.target.value;
    items[documentIndex] = item;
    setStudentList(items);
  };

  const updateAttendance = async () => {
    studentList.map((student) => {
      student.meeting_id = props.liveClassDetail.id
    })
    setStudentList(studentList);

    setBtnLoading(true);
    setSpinner(true);

    const payload = {
      student_list: studentList
    };

    try {
      const res = await postRequest(
        "student-live-class-attended-update",
        payload
      );

      setSpinner(false);
      if (res.data.status === "success") {
        SuccessNotificationMsg("Success", res.data.message);
        setBtnLoading(false);
        window.location.href = "/live-class";
      } else {
        ErrorNotificationMsg(res.data.message);
        setBtnLoading(false);
      }
    } catch (error) {
      ErrorNotificationMsg("Error in updated attendance.");
      setBtnLoading(false);
      setSpinner(false);
    }
  };

  let classDateTime =
    moment(props.liveClassDetail.live_class_date, "DD-MM-YYYY").format("YYYY-MM-DD") +
    " " +
    moment(props.liveClassDetail.start_time, "HH:mm A").format("HH:mm") +
    ":00";

  let diffinMinutes = moment().diff(classDateTime, "minutes");

  return (
    <>
      {diffinMinutes > 0 ?
        <a
          className="btn btn-xs btn-outline-info ml-2"
          onClick={getUserType() === "staff" ? () => showModelFunction() : ""}
        >
          Attendance
        </a>
        :
        <a
          disabled={true}
          className="btn btn-xs btn-outline-info ml-2"
        >
          Attendance
        </a>
      }

      <Modal
        title="Attendent Student List"
        open={showModel}
        onCancel={hideModelFunction}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={false}
      >
        <div className="row">
          <div className="col-md-12 mb-3">
            <span className="d-block">
              <h3>Subject : <strong>{props.liveClassDetail?.subject_name}</strong></h3>
            </span>
          </div>
        </div>

        <div className="row">
          <div className="table-responsive">
            <table className="table table-sm table-bordered table-hover">
              <thead className="thead-themed">
                <tr className="text-center">
                  <th>SL.</th>
                  <th>NAME</th>
                  <th>CLASS</th>
                  <th>ROLL</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {studentList &&
                  studentList.map((student, id) => {
                    return (
                      <tr key={id}>
                        <td className="text-right">{id + 1}</td>
                        <td>{student?.student_name}</td>
                        <td className="text-center">{student?.class}</td>
                        <td className="text-right">{student?.roll}</td>
                        <td className="text-center">
                          {moment().diff(
                            moment(props.liveClassDetail.live_class_date, "DD-MM-YYYY"),
                            "days"
                          ) === 0 ?
                            <Radio.Group
                              key={id}
                              onChange={(e) => onChange(e, student)}
                              value={student?.is_meeting_attended}
                            >
                              <Radio value={1}>Present</Radio>
                              <Radio value={0}>Absent</Radio>
                              <Radio value={2}>Late</Radio>
                            </Radio.Group> :
                            <Radio.Group
                              key={id}
                              value={student?.is_meeting_attended}
                              disabled={true}
                            >
                              <Radio value={1}>Present</Radio>
                              <Radio value={0}>Absent</Radio>
                              <Radio value={2}>Late</Radio>
                            </Radio.Group>
                          }
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div className="col-md-12">
          <div className="panel-content d-flex flex-row justify-content-end">
            <Space>
              <button
                onClick={hideModelFunction}
                className="btn btn-default ml-auto waves-effect waves-themed"
              >
                Close
              </button>
              {moment().diff(
                moment(props.liveClassDetail.live_class_date, "DD-MM-YYYY"),
                "days"
              ) === 0 && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={updateAttendance}
                    loading={btnLoading}
                    className="btn btn-info ml-auto waves-effect waves-themed"
                  >
                    Update Attendance
                  </Button>
                )}
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LiveClassAttendentList;
