import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { postRequest } from "../../axios";
import { getSessionData, getSchoolData } from "../../utils/Helpers";

import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import TabulationActivitiesModal from "./TabulationActivitiesModal";

const StudentListActivities = (props) => {
  const [studentList, setStudentList] = useState([]);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getStudentActivityList();
  }, []);

  const getStudentActivityList = async () => {
    let stdClass = props.classSection.slice(0, -2);
    let stdSection = props.classSection.slice(-1);

    setSpinner(true);
    const response = await postRequest("exam-activity-tabulation-student-list", {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      stdClass: stdClass,
      stdSection: stdSection,
      examId: props.examId
    });

    setSpinner(false);
    if (response.data.response === "") {
      ErrorNotificationMsg(response.data.errmsg);
    } else {
      setStudentList(response.data.response.data.activityArray);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : {props.classSection}</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered table-hover">
                    <thead className="thead-themed">
                      <tr className="text-center">
                        <th className="text-right">ROLL</th>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!studentList &&
                        studentList.map((student) => {
                          return (
                            <tr key={student?.sid}>
                              <td>{student?.roll}</td>
                              <td>{student?.stdId}</td>
                              <td><strong>{student?.stdName}</strong></td>
                              <td className="text-center">
                                <TabulationActivitiesModal
                                  studentName={student?.stdName}
                                  cid={student?.cid}
                                  examId={props.examId}
                                  history={props.history}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListActivities;