import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Modal } from "antd";

import { postRequest } from "../../axios";
import { getSchoolData, getSchoolMenu, getSessionData, getUserData } from "../../utils/Helpers";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

const ButtonUI = (props) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [callUrl, setCallUrl] = useState(null);
  const setSpinner = useOutletContext();

  const checkPaymentMode = () => {
    if ((getSchoolData().appPaymentDetails.paymentMode === 'online') && (getUserData().appPaidStatus === 0)) {
      if (props.url !== '/notice-board') {
        showMessageBox();
      } else {
        navigate(props.url);
      }
    } else {
      navigate(props.url);
    }
  }

  const hideMessageBox = () => {
    setShowModal(false);
  };

  const showMessageBox = async () => {
    setSpinner(true);

    const payload = {
      form_data: {
        pgType: "PayUmoney",
        schoolCode: getSchoolData().school_code,
        sessionCode: getSessionData().rcode,
        sid: getUserData().referenceId,
        amount: getSchoolData().appPaymentDetails.amount,
        payTo: getSchoolData().appPaymentDetails.payTo,
        platform: "web"
      },
    };

    setBtnLoading(true);

    try {
      let res = await postRequest("get-app-payment-gateway-detail", payload);

      setSpinner(false);
      if (res.data.error === 0) {
        setBtnLoading(false);
        setCallUrl(res.data.response.data.callUrl);
        setShowModal(true);
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg("Error in fees payment.");
      }
    } catch (error) {
      setBtnLoading(false);
      setSpinner(false);
      ErrorNotificationMsg("Something went wrong!!");
    }
  };

  return (
    <>
      <div
        className="col-sm-4 col-xl-4 pointer"
        onClick={() => checkPaymentMode()}
      >
        <div
          className={
            "p-3 " +
            props.color +
            " rounded overflow-hidden position-relative text-white mb-g"
          }
        >
          <div>
            <h3 className="display-5 d-block l-h-n m-0 fw-500 text-white">
              {props.title}
              <small className="m-0 l-h-n">{props.subtitle}</small>
            </h3>
          </div>
          <i
            className={
              props.icon +
              " position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
            }
            style={{ fontSize: "6rem" }}
          ></i>
        </div>
      </div>

      <Modal
        title="PAYMENT DUE"
        open={showModal}
        onCancel={hideMessageBox}
        footer={false}
      >
        <form action={callUrl} method="POST">
          <pre className="text-wrap">
            {getSchoolData().appPaymentDetails.message}
          </pre>
          <input
            type="submit"
            value={"PAY NOW"}
            className="btn btn-primary ml-auto waves-effect waves-themed"
          />
        </form>

      </Modal>
    </>
  );
};

const BigButtonUI = (props) => {
  const navigate = useNavigate();

  return (
    <div
      className="col-sm-4 col-xl-4 pointer"
      onClick={() => navigate(props.url)}
    >
      <div
        className={
          "p-3 " +
          props.color +
          " rounded overflow-hidden position-relative text-white mb-g"
        }
      >
        <div>
          <h3 className="display-4 d-block l-h-n m-0 fw-500 text-white">
            {props.title}
            <small className="m-0 l-h-n">{props.subtitle}</small>
          </h3>
        </div>
        <i
          className={
            props.icon +
            " position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1"
          }
          style={{ fontSize: "6rem" }}
        ></i>
      </div>
    </div>
  );
};

const DashboardStudent = (props) => {
  const setSpinner = useOutletContext();

  useEffect(() => {
    setSpinner(false);
  }, [])

  return (
    <div className="content">
      {/* <div className="row">
        <BigButtonUI
          title="Update Profile"
          subtitle="Details"
          url="/student-profile-edit"
          icon="fal fa-user"
          color="bg-danger-800"
          history={props.history}
        />
      </div> */}

      <div className="row">
        {getSchoolMenu().indexOf("nb") !== -1 &&
          <ButtonUI
            title="Notice Board"
            subtitle="Show List"
            url="/notice-board"
            icon="fal fa-file-edit"
            color="bg-primary-300"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("hw") !== -1 &&
          <ButtonUI
            title="Homework"
            subtitle="Show List"
            url="/home-work"
            icon="fal fa-book-reader"
            color="bg-warning-400"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("cd") !== -1 &&
          <ButtonUI
            title="Class Diary"
            subtitle="Show List"
            url="/class-diary"
            icon="fal fa-books"
            color="bg-info-400"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("lc") !== -1 &&
          <ButtonUI
            title="Live Class"
            subtitle="Show List"
            url="/live-class"
            icon="fal fa-camcorder"
            color="bg-warning-600"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("fe") !== -1 &&
          <ButtonUI
            title="Fees"
            subtitle="Details"
            url="/fees"
            icon="fal fa-receipt"
            color="bg-danger-500"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("ar") !== -1 &&
          <ButtonUI
            title="Academic Report"
            subtitle="Details"
            url="/academic-report-student"
            icon="fal fa-clipboard-list-check"
            color="bg-primary-600"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("sp") !== -1 &&
          <ButtonUI
            title="My Profile"
            subtitle="Details"
            url="/student-profile"
            icon="fal fa-user"
            color="bg-info-500"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("al") !== -1 &&
          <ButtonUI
            title="Almanac"
            subtitle="List"
            url="/almanac"
            icon="fal fa-book"
            color="bg-success-500"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("ac") !== -1 &&
          <ButtonUI
            title="Academic Calendar"
            subtitle="List"
            url="/academic-calendar"
            icon="fal fa-book"
            color="bg-success-800"
            history={props.history}
          />
        }

        {getSchoolMenu().indexOf("tt") !== -1 &&
          <ButtonUI
            title="Time Table"
            subtitle="List"
            url="/time-table"
            icon="fal fa-calendar-week"
            color="bg-danger-500"
            history={props.history}
          />
        }

        <ButtonUI
          title="Holiday List"
          subtitle="List"
          url="/holiday-list"
          icon="fal fa-calendar-day"
          color="bg-warning-500"
          history={props.history}
        />
      </div>
    </div>
  );
};

export default DashboardStudent;
