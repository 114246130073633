import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../Config";
import { useParams } from "react-router-dom";
import userIcon from "../../images/userIcon.jpg";

const AppStudentProfileDetails = (props) => {
  const { token, dbtoken, sid } = useParams();
  const [studentProfile, setStudentProfile] = useState(null);

  const headers = {
    DBAuth: dbtoken,
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    getStudentProfile();
  }, []);

  const getStudentProfile = async () => {
    const response = await axios.post(Config.API_URL + "get-student-personal-details", {
      student_id: sid,
    }, { headers });
    setStudentProfile(response.data.response);
  };

  return (
    <div id="panel-1" className="panel">
      <div className="panel-container show">
        <div className="panel-content p-0">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-2">
              <div className="card border m-auto m-lg-0">
                <img
                  src={studentProfile?.image_url}
                  className="img-thumbnail"
                  alt="Profile Photo"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = userIcon;
                  }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5">
              <table className="table table-sm table-bordered table-hover table-striped w-100">
                <thead className="thead-themed">
                  <tr><th colSpan={2} className="text-center">PERSONAL DETAILS</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Student's ID</td><td><strong>{studentProfile?.std_id}</strong></td>
                  </tr>
                  {studentProfile?.std_bs_code &&
                    <tr>
                      <td>Banglar Siksha ID</td><td><strong>{studentProfile?.std_bs_code}</strong></td>
                    </tr>
                  }
                  <tr>
                    <td>Student's Name </td><td><strong>{studentProfile?.std_name}</strong></td>
                  </tr>
                  <tr>
                    <td>Date of Birth</td><td>{studentProfile?.std_birth_date}</td>
                  </tr>
                  <tr>
                    <td>Gender </td><td>{studentProfile?.std_gender_text}</td>
                  </tr>
                  <tr>
                    <td>Caste</td><td>{studentProfile?.std_caste_text}</td>
                  </tr>
                  <tr>
                    <td>Blood Group</td><td>{studentProfile?.std_blood_group_text}</td>
                  </tr>
                  <tr>
                    <td>Religion</td><td>{studentProfile?.std_religion_text}</td>
                  </tr>
                  <tr>
                    <td>Aadhaar Card No.</td><td>{studentProfile?.std_aadhaar}</td>
                  </tr>
                  <tr>
                    <td>Second Language</td><td>{studentProfile?.second_lang}</td>
                  </tr>
                  <tr>
                    <td>Third Language</td><td>{studentProfile?.third_lang}</td>
                  </tr>
                  <tr>
                    <td>House</td><td>{studentProfile?.std_house}</td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-sm table-bordered table-hover table-striped w-100">
                <thead className="thead-themed">
                  <tr><th colSpan={2} className="text-center">ADMISSION DETAILS</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Admission Number</td><td><strong>{studentProfile?.std_adm_no}</strong></td>
                  </tr>
                  <tr>
                    <td>Date of Admission</td><td><strong>{studentProfile?.std_adm_date}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5">
              <table className="table table-sm table-bordered table-hover table-striped w-100">
                <thead className="thead-themed">
                  <tr><th colSpan={2} className="text-center">PARENT DETAILS</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Father's Name</td><td>{studentProfile?.std_father_name}</td>
                  </tr>
                  <tr>
                    <td>Father's Contact No.</td><td>{studentProfile?.std_father_aadhaar}</td>
                  </tr>
                  <tr>
                    <td>Mother's Name</td><td>{studentProfile?.std_mother_name}</td>
                  </tr>
                  <tr>
                    <td>Mother's Contact No.</td><td>{studentProfile?.std_mother_aadhaar}</td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-sm table-bordered table-hover table-striped w-100">
                <thead className="thead-themed">
                  <tr><th colSpan={2} className="text-center">CONTACT DETAILS</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Address</td>
                    <td>
                      <span className="d-block">Village - {studentProfile?.std_village}</span>
                      <span className="d-block">P.O. - {studentProfile?.std_po}</span>
                      <span className="d-block">P.S. - {studentProfile?.std_ps}</span>
                      <span className="d-block">Dist. - {studentProfile?.std_district_text}</span>
                      <span className="d-block">PIN - {studentProfile?.std_pin}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Primary Mobile No.</td><td>{studentProfile?.std_contact}</td>
                  </tr>
                  <tr>
                    <td>Whatsapp</td><td>{studentProfile?.std_contact_whatsapp}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStudentProfileDetails;