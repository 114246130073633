import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Space,
  Select,
} from "antd";
import { postRequest } from "../../axios";
import { getSessionData, getSchoolData, getUserData } from "../../utils/Helpers";

import { ErrorNotificationMsg, SuccessNotificationMsg, } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";
import { Option } from "antd/lib/mentions";

const StudentListWrittenOral = (props) => {
  const formRef = useRef();
  const [state, setState] = useState({
    marksMethod: null,
    examMethod: null,
  });
  const [tabulation, setTabulation] = useState(null);
  const [marksList, setMarksList] = useState([]);
  const [gradingList, setGradingList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getTabulation();
  }, []);

  const getTabulation = async () => {
    setSpinner(true);
    const response = await postRequest("exam-marks-tabulation-list", {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      subjectId: props.subjectId,
      userId: getUserData().tid,
      token: "c874792257ded66e",
    });

    setSpinner(false);
    if (response.data.error > 0) {
      ErrorNotificationMsg(response.data.errmsg);
    } else {
      setTabulation(response.data.response.data);
      setState({
        ...state,
        marksMethod: response.data.response.data.marksMethod,
        examType: response.data.response.data.examType,
      });
      setMarksList(response.data.response.data.students);

      if (response.data.response.data.marksMethod === 'grading') {
        setGradingList(response.data.response.data.gradingArray);
      }
    }
  };

  const checkMarks = (event, student, type) => {
    let fm = 0;
    if (type === "W") {
      fm = tabulation.fullMarks.written;
    } else {
      fm = tabulation.fullMarks.oral;
    }

    let mo = event.target.value;
    if (mo !== '111' && mo !== '222' && mo !== '333') {
      if (fm < mo) {
        event.target.value = 0;
        handleMarksChange(event, student, type);
        ErrorNotificationMsg("Marks can not be more than " + fm);
      }
    }
  };

  const handleMarksChange = (event, student, type) => {
    let mo = event.target.value;
    let items = [...marksList];
    let documentIndex = items.findIndex(
      (res) => res.classId === student.classId
    );
    let item = { ...items[documentIndex] };
    if (type === 'W') {
      item.written = mo;
    } else {
      item.oral = mo;
    }
    item.grade = 0;
    items[documentIndex] = item;

    setMarksList(items);
  }

  const handleGradeChange = (value, student) => {
    let items = [...marksList];
    let documentIndex = items.findIndex(
      (res) => res.classId === student.classId
    );
    let item = { ...items[documentIndex] };
    item.value = value;

    gradingList.map((grd) => (
      grd.id === value ? item.grade = grd.value : ""
    ))

    items[documentIndex] = item;

    setMarksList(items);
  };

  const onFinish = async () => {
    setBtnLoading(true);
    setSpinner(true);

    let marksArr = [];
    marksList.map((mInfo) => {
      marksArr.push({
        classId: mInfo.classId,
        marksWritten: mInfo.written,
        marksOral: mInfo.oral,
        marksGrade: mInfo.value,
      });
    });

    const payload = {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      subjectId: props.subjectId,
      userId: getUserData().tid,
      form_data: {
        marksMethod: state.marksMethod,
        examType: state.examType,
        marks: marksArr
      }
    };

    try {
      const res = await postRequest("exam-marks-tabulation-update", payload);

      setSpinner(false);
      if (res.data.error === 0) {
        SuccessNotificationMsg("Success", "Marks successfully updated.");
        setBtnLoading(false);
        props.handleShowList(false);
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg("Sorry!! Unable to save marks.");
      }
    } catch (error) {
      setBtnLoading(false);
      setSpinner(false);
      ErrorNotificationMsg("Something went wrong!!");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : {props.classSection}</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                {state.marksMethod !== 'grading' ?
                  <div className="form-row">
                    <div className="col-md-12 mb-2">
                      <div className="alert alert-danger fade show px-3 py-2">
                        Please enter [ <strong>111</strong> if Absent (AB) ] & [ <strong>222</strong> if Not Applicable (NA) ]
                      </div>
                    </div>
                  </div> : ""
                }

                <Form
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                  ref={formRef}
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>ROLL</th>
                          <th>NAME</th>
                          {state.marksMethod === 'grading' ?
                            <>
                              <th>GRADE</th>
                            </>
                            :
                            <>
                              <th>WRITTEN [F.M.- {tabulation?.fullMarks?.written}]</th>
                              {tabulation?.examType === 'written-oral' && (
                                <th>ORAL [F.M.- {tabulation?.fullMarks?.oral}]</th>
                              )}
                            </>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {marksList &&
                          marksList.map((student) => {
                            return (
                              <tr key={student?.classId}>
                                <td>{student?.stdRoll}</td>
                                <td><strong>{student?.stdName}</strong></td>
                                {state.marksMethod === 'grading' ?
                                  <>
                                    <td>
                                      <Select
                                        allowClear
                                        name="grade"
                                        placeholder="Select Grade"
                                        className="form-control"
                                        value={student?.grade}
                                        id={student?.classId}
                                        onChange={(event) =>
                                          handleGradeChange(event, student)
                                        }
                                      >
                                        {!!gradingList &&
                                          gradingList.map((gl) => (
                                            <Option key={gl.id} value={gl.id}>
                                              {gl.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </td>
                                  </>
                                  :
                                  <>
                                    <td>
                                      {tabulation?.lockTabulation === 1 ?
                                        <Input
                                          type="text"
                                          name="written"
                                          value={student?.written}
                                          disabled={true}
                                        />
                                        :
                                        <Input
                                          type="text"
                                          name="written"
                                          value={student?.written}
                                          id={student?.classId}
                                          onBlur={(event) => checkMarks(event, student, "W")}
                                          onChange={(event) => handleMarksChange(event, student, "W")}
                                        />
                                      }
                                    </td>
                                    {tabulation?.examType === 'written-oral' && (
                                      <td>
                                        {tabulation?.lockTabulation === 1 ?
                                          <Input
                                            type="text"
                                            name="oral"
                                            value={student?.oral}
                                            disabled={true}
                                          />
                                          :
                                          <Input
                                            type="text"
                                            name="oral"
                                            value={student?.oral}
                                            id={student?.classId}
                                            onBlur={(event) => checkMarks(event, student, "O")}
                                            onChange={(event) => handleMarksChange(event, student, "O")}
                                          />
                                        }
                                      </td>
                                    )}
                                  </>
                                }
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  {tabulation?.lockTabulation !== 1 && (
                    <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                      <Space>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={btnLoading}
                          className="btn btn-primary ml-auto waves-effect waves-themed"
                        >
                          Save Marks
                        </Button>
                      </Space>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListWrittenOral;