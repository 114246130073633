import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Form,
  Space,
} from "antd";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import {
  getUserData
} from "../../utils/Helpers";
import ReportCard from "./ReportCard";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

const AcademicReportStudent = () => {
  const [state, setState] = useState({
    exam_id: null,
  });
  const [examList, setExamList] = useState([]);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getExamList();
  }, []);

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const getExamList = async () => {
    setSpinner(true);
    const examRes = await postRequest("exam-get-report-card-exam-list");
    setExamList(examRes.data.response.examList);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Academic Report"
        pageIcon={<i className="subheader-icon fal fa-clipboard-list-check"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Academic Report</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={[15]} className="mb-3">
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        name="exam_id"
                        label="Examination"
                        rules={[
                          {
                            required: true,
                            message: "Please select exam!",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          placeholder="Select Exam"
                          onChange={(value) =>
                            handleSelectChange("exam_id", value)
                          }
                        >
                          {!!examList &&
                            examList.map((s) => (
                              <Option
                                key={s.examId}
                                value={s.examId}
                              >
                                {s.examName}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                  <Space>
                    {getUserData().classId && (
                      <ReportCard
                        classId={getUserData().classId}
                        examId={state.exam_id}
                      />
                    )}
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicReportStudent;
