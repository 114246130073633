import React, { useState, useEffect } from "react";
import { LinkItEmail, LinkItUrl } from 'react-linkify-it';
import { postRequest } from "../axios";

import PageHeader from "./common/PageHeader";

import userIcon from "../images/userIcon.jpg";
import { useOutletContext } from "react-router-dom";

const ContactUs = () => {
  const [loginUserInfo, setLoginUserInfo] = useState([]);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getContactUs();
  }, []);

  const getContactUs = async () => {
    setSpinner(true);
    const response = await postRequest("login-user-info");
    setLoginUserInfo(response.data.response);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Contact Us"
        pageIcon={<i className="subheader-icon fal fa-phone-square"></i>}
      />
      <div className="row">
        <div className="col-lg-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Contact Us</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card mb-g rounded-top">
                      <div className="row no-gutters row-grid">
                        <div className="col-12">
                          <div className="d-flex flex-column align-items-center justify-content-center p-4">
                            <img
                              style={{ width: 120, height: 120 }}
                              src={loginUserInfo.school_image?.school_logo}
                              className="profile-image rounded-circle"
                              alt="School Logo"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = userIcon;
                              }}
                            />
                            <span className="fw-700 text-center mt-3">
                              <h2>{loginUserInfo.school_information?.sch_name}</h2>
                              <span className="d-block">{loginUserInfo.school_information?.sch_type}</span>
                              <div className="p-3">
                                <span className="d-block"><i className="fal fa-mobile-alt text-muted mr-2"></i> {loginUserInfo.school_information?.sch_contact_land}</span>
                                <span className="d-block"><i className="fal fa-mailbox text-muted mr-2"></i> <LinkItEmail>{loginUserInfo.school_information?.sch_email}</LinkItEmail></span>
                                <span className="d-block"><i className="fal fa-globe text-muted mr-2"></i> <LinkItUrl>{loginUserInfo.school_information?.sch_website}</LinkItUrl></span>
                                <address className="mt-4">
                                  <i className="fal fa-map-pin mr-2"></i> {loginUserInfo.school_information?.address}
                                </address>
                              </div>
                            </span>
                            <hr />
                            <div className="mt-1 text-center demo">
                              <a href={loginUserInfo.school_information?.facebook} className="fa-2x" rel="opener" style={{ color: "#3b5998" }}>
                                <i className="fab fa-facebook"></i>
                              </a>
                              <a href={loginUserInfo.school_information?.twitter} className="fa-2x" rel="opener" style={{ color: "#38A1F3" }}>
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href={loginUserInfo.school_information?.youtube} className="fa-2x" rel="opener" style={{ color: "#db3236" }}>
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href={loginUserInfo.school_information?.instagram} className="fa-2x" rel="opener" style={{ color: "#0077B5" }}>
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href={`https://wa.me/91` + loginUserInfo.school_information?.whatsapp} className="fa-2x" rel="opener" style={{ color: "#4FCE5D" }}>
                                <i className="fab fa-whatsapp"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div id="panel-2" className="panel">
                      <div className="panel-hdr">
                        <h2>Other Details</h2>
                      </div>
                      <div className="panel-container show">
                        <div className="panel-content">
                          {loginUserInfo.school_information?.other_details}
                        </div>
                      </div>
                    </div>

                    <div id="panel-3" className="panel">
                      <div className="panel-hdr">
                        <h2>Visiting Hours</h2>
                      </div>
                      <div className="panel-container show">
                        <div className="panel-content">
                          {loginUserInfo.school_information?.visiting_time &&
                            loginUserInfo.school_information?.visiting_time.map((vl, id) => {
                              return (
                                <div className="frame-wrap mb-2" key={id + 1}>
                                  <span className="d-block"> Person : <strong> {vl[0].person}</strong></span>
                                  <span className="d-block"> Day : <strong> {vl[1].day}</strong></span>
                                  <span className="d-block"> Time : <strong> {vl[2].time}</strong></span>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
